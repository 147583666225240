export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const FORGOT_PASSWORD = "forgotPassword";
export const RESET_PASSWORD = "resetPassword";
export const CHECK_AUTH = "checkAuth";
export const LOG_ERROR = "logError";

export const GET_EMAILS = "getEmails";
export const GET_EMAIL = "getEmail";
export const CHANGE_READ_STATUS = "changeReadStatus";
export const MARK_SELECTED_READ = "markMultipleRead";
export const MOVE_TO_FOLDER = "moveToFolder";
export const MOVE_TO_JUNK = "moveToJunk";
export const BLOCK_SENDER = "blockSender";
export const MOVE_TO_JUNK_MULTIPLE = "moveToJunkMultiple";
export const MOVE_SELECTED_TO_FOLDER = "moveToFolderMultiple";
export const SEND_EMAIL = "sendEmail";
export const SEND_REPLY = "sendReply";
export const SEND_FORWARD = "sendForward";
export const DELETE_EMAIL = "deleteEmail";
export const DELETE_MULTIPLE_EMAILS = "deleteEmailsMultiple";
export const RESET_EMAIL = "resetEmail";

export const GET_FOLDERS = "getFolders";
export const GET_FOLDER = "getFolder";
export const CREATE_FOLDER = "createFolder";
export const SEARCH_EMAILS = "searchEmails";
export const RENAME_FOLDER = "renameFolder";
export const DELETE_FOLDER = "deleteFolder";
export const RESET_FOLDER = "resetFolder";

export const GET_USERS = "getUsers";
export const GET_USER = "getUser";
export const ADD_USER = "addUser";
export const UPDATE_USER = "updateUser";
export const DELETE_USER = "deleteUser";
export const SEND_RESET_PASSWORD = "sendResetPassword";
export const RESET_USER = "resetUser";

export const GET_RULES = "getRules";
export const GET_RULE = "getRule";
export const CREATE_RULE = "createRule";
export const UPDATE_RULE = "updateRule";
export const DELETE_RULE = "deleteRule";
export const RESET_RULE = "resetRule";

export const GET_BULK_RULES = "getBulkRules";
export const GET_BULK_RULE = "getBulkRule";
export const CREATE_BULK_RULE = "createBulkRule";
export const UPDATE_BULK_RULE = "updateBulkRule";
export const DELETE_BULK_RULE = "deleteBulkRule";
export const RESET_BULK_RULE = "resetBulkRule";

export const GET_PENDING_DOMAINS = "getPendingDomains";
export const GET_INCOMPLETE_DOMAINS = "getIncompleteDomains";
export const UPLOAD_DOMAINS = "uploadDomains";
export const REGISTER_DOMAIN = "registerDomain";
export const GET_DOMAINS = "getDomains";
export const GET_DOMAIN_COUNTS = "getDomainCounts";
export const UPDATE_DOMAIN = "updateDomain";
export const DELETE_DOMAIN = "deleteDomain";
export const RESET_DOMAIN = "resetDomain";

export const FETCH_SEARCH_RESULTS = "fetchSearchResults";
