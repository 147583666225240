
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPaperclip, faCircle } from "@fortawesome/free-solid-svg-icons";
import { defineComponent } from "@vue/runtime-core";
import { EnumService } from "@/common/enum.service";

library.add(faPaperclip, faCircle);

const EmailListItem = defineComponent({
  name: "EmailListItem",
  data: () => ({
    enumService: new EnumService()
  }),
  props: {
    selected: {
      type: Boolean,
      default: false
    },
    email: {
      type: Object
    },
    isBulkSelected: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    emailTyped: {
      get: function () {
        return this.email;
      },
      set: function () {
        // Do nothing
      }
    },
    internalSelected: {
      get: function () {
        return this.isBulkSelected;
      },
      set: function () {
        this.$emit("bulkSelected", {
          id: this.email?.id,
          isReadBySelf: this.email?.isReadBySelf
        });
      }
    }
  },
  methods: {
    clicked() {
      this.$emit("clicked", this.email?.id);
    },
    bulkSelected() {
      // this.isBulkSelected = !this.isBulkSelected;
      this.$emit("bulkSelected", {
        id: this.email?.id,
        isReadBySelf: this.email?.isReadBySelf
      });
    },
    getAddressToolTip(addresses: Array<string>) {
      let newAddresses = [...addresses];

      newAddresses = newAddresses.splice(1, 1);

      return newAddresses.join(", ");
    }
  }
});
export default EmailListItem;
