
import { mapState } from "vuex";
import VPagination from "./VPagination.vue";
import EmailListItem from "./EmailListItem.vue";
import { Menu, MenuButton, MenuItems } from "@headlessui/vue"

import {
  GET_EMAILS,
  MOVE_SELECTED_TO_FOLDER,
  DELETE_MULTIPLE_EMAILS,
  RESET_EMAIL,
  MARK_SELECTED_READ,
  MOVE_TO_JUNK_MULTIPLE
} from "../store/actions.type";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faEllipsisV,
  faTrashAlt,
  faBan,
  faSpinner,
  faFolderOpen,
  faTimesCircle,
  faEnvelopeOpen,
  faEnvelope,
  faCalendar,
  faSort,
  faArrowUp,
  faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import { defineComponent, State, watch } from "vue";
import { toast } from "@/common/toast/toast.service";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css"

import VueResizable from "vue-resizable";
import CustomMenuItem, { MenuItemDefinition } from "@/components/common/CustomMenuItem.vue";

declare var $: any;

library.add(
  faSearch,
  faEllipsisV,
  faTrashAlt,
  faBan,
  faSpinner,
  faFolderOpen,
  faTimesCircle,
  faEnvelopeOpen,
  faEnvelope,
  faCalendar,
  faSort,
  faArrowUp,
  faArrowDown
);

interface ISelected {
  id: string;
  isRead: boolean;
  isReadBySelf: boolean;
}
const EmailList = defineComponent({
  name: "EmailList",
  components: {
    CustomMenuItem,
    VPagination,
    EmailListItem,
    Datepicker,
    VueResizable,
    Menu,
    MenuButton,
    MenuItems
  },
  props: {
    itemsPerPage: {
      type: Number,
      required: false,
      default:
        parseInt(window.sessionStorage.getItem("itemsPerPage") ?? "0") || 25
    },
    folderId: {
      type: String,
      default: "c57e0125-aa61-4c63-a26f-1147aac07700"
    },
    emailId: {
      type: String
    },
    isProtected: {
      type: Boolean,
      default: false
    },
    emailSearch: {
      type: String
    },
    canDeleteEmailsIfProtected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPage: 1,

      bulkSelected: new Array<ISelected>(),

      modalActionName: "",
      folderModalOpen: false,

      search: "",
      sender: "",
      recipient: "",
      subject: "",
      dropdownOpen: false,
      searchView: false,

      bulkTargetFolderId: "",

      loading: true,
      error: "",

      showDateRange: false,
      date: null,
      allFolders: false,

      selectedSortBy: (null as unknown) as MenuItemDefinition,
      sortIsDescending: null as boolean | null,
      sortByMenuItems: new Array<MenuItemDefinition>(),
      defaultSortByMenuItem: { name: "Default", key: null } as MenuItemDefinition
    };
  },
  async mounted() {
    this.sortByMenuItems.push(
      this.defaultSortByMenuItem,
      { name: "From" },
      { name: "Date", key: "DateCreated" },
      { name: "Subject" });

    this.selectedSortBy = this.defaultSortByMenuItem;

    this.getEmails();
    this.error = "";
    this.loading = true;
    watch(
      [
        () => this.folderId,
        () => this.$route.params.currentPage,
        () => this.currentPage,
        () => this.allFolders,

        () => this.selectedSortBy,
        () => this.sortIsDescending
      ],
      (
        [newFolderId, newRoutePage, newCurrentPage, newAllFolders, newSelectedMenuItem, newSortIsDescending],
        [oldFolderId, oldRoutePage, oldCurrentPage, oldAllFolders, oldSelectedMenuItem]
      ) => {
        if (newAllFolders !== oldAllFolders && (this.date != null || (this.search || "").trim().length > 0)) {
          this.searchEmails();
        }
        if (newSelectedMenuItem != oldSelectedMenuItem && newSelectedMenuItem == this.defaultSortByMenuItem || newSortIsDescending != null) {
          this.getEmails();
        }
        if (newRoutePage !== oldRoutePage) {
          this.currentPage = newRoutePage as any as number;
        }
        const currentPageChanged =
          newCurrentPage !== oldCurrentPage && oldCurrentPage !== undefined;
        const currentFolderChanged =
          newFolderId !== oldFolderId && oldFolderId !== undefined;
        if (currentFolderChanged && this.currentPage !== 1) {
          this.currentPage = 1;
        } else if (currentPageChanged || currentFolderChanged) {
          this.listConfig.folderId = newFolderId as any as string;
          this.getEmails();
        }
      }
    );
  },
  computed: {
    ...mapState({
      folders: (state: any) => (state as State).folders,
      folder: (state: any) => (state as State).folders.folder,
      emails: (state: any) => (state as State).emails.emails,
      email: (state: any) => (state as State).emails.email,
      emailsPagination: (state: any) => (state as State).emails.emailsPagination
    }),
    isDeleteEmailDisabled: {
      get: function () {
        return (
          ((this.isProtected ?? false).toString().toLowerCase() === "true" && (this.canDeleteEmailsIfProtected ?? false).toString().toLowerCase() === "false") ||
          this.bulkSelected.length === 0
        );
      },
      set: () => null
    },
    internalSearch: {
      get: function () {
        return this.emailSearch;
      },
      set: function () {
        this.$emit("UpdateSearchFolder", this.searchView, this.allFolders);
      }
    },
    listConfig() {
      const filters = {
        paginationInformation: {
          currentPage: this.currentPage,
          itemsPerPage:
            parseInt(window.sessionStorage.getItem("itemsPerPage") ?? "0") || 25
        },
        search: this.search,
        sender: this.sender,
        recipient: this.recipient,
        subject: this.subject,
        folderId: this.folderId,
        selectedEmailId: "" as any,
        propertyToSortBy: this.selectedSortBy?.key === undefined ? this.selectedSortBy?.name : this.selectedSortBy?.key,
        includeAllFolders: this.allFolders,
        sortIsDescending: this.sortIsDescending,
        fromDate: this.date != null ? this.removeTimeFromDate(this.date[0]) : null,
        toDate: this.date != null ? this.removeTimeFromDate(this.date[1]) : null,
      };

      if (this.$route.params.emailId) {
        filters.selectedEmailId = this.$route.params.emailId;
      }

      return filters;
    },
    isAllSelectedUnread() {
      return (
        this.bulkSelected.every((x) => x.isReadBySelf === false) &&
        this.bulkSelected.length !== 0
      );
    },
    selectAll: {
      get: function () {
        return this.emails && this.emails.length
          ? this.bulkSelected.length === this.emails.length
          : false;
      },
      set: function (value: boolean) {
        const selected = new Array<any>();

        if (value) {
          this.emails.forEach(function (email: any) {
            selected.push({ id: email.id, isReadBySelf: email.isReadBySelf });
          });
        }
        this.bulkSelected = selected;
      }
    }
  },
  watch: {
    emailSearch: function (newVal) {
      this.search = newVal;
      this.searchView = true;
    }
  },
  methods: {
    formatDate(date: Date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
    removeTimeFromDate(date: Date) {
      return new Date(date.toDateString())
    },
    setSortingArrow(e: PointerEvent, selectedSortBy: MenuItemDefinition) {
      this.selectedSortBy = selectedSortBy;

      this.sortIsDescending = this.sortIsDescending == null ? false : !this.sortIsDescending ? true : null
      this.selectedSortBy = selectedSortBy != this.defaultSortByMenuItem && this.sortIsDescending == null ? this.defaultSortByMenuItem : selectedSortBy;

      e.stopImmediatePropagation();
    },
    getEmails() {
      this.loading = true;
      this.giveUpdatedEmailCount();
      this.$store.dispatch(RESET_EMAIL);
      this.$store.dispatch(GET_EMAILS, this.listConfig).then(() => {
        this.loading = false;
        this.bulkSelected = [];
        this.bulkTargetFolderId = "";
      });
    },
    giveUpdatedEmailCount() {
      this.$emit("updatedEmailCount", 10);
    },
    toggleFolderModal() {
      this.folderModalOpen = !this.folderModalOpen;
    },
    async moveToFolderMultiple() {
      if (this.bulkTargetFolderId === "") {
        toast.error("Please select a folder to move selected emails to");
        return;
      }
      this.$store
        .dispatch(MOVE_SELECTED_TO_FOLDER, {
          ids: this.bulkSelected.map((x) => x.id).join(","),
          folderId: this.bulkTargetFolderId
        })
        .then(() => {
          this.bulkSelected = [];
          this.getEmails();
          $("#bulkActionModal").modal("hide");
        });
    },
    async deleteEmails() {
      if (this.bulkSelected.length === 0) {
        toast.error("You haven't selected any emails to delete.");
        return;
      }
      this.$store
        .dispatch(DELETE_MULTIPLE_EMAILS, {
          ids: this.bulkSelected.map((x) => x.id).join(",")
        })
        .then(() => {
          this.bulkSelected = [];
          this.getEmails();
          $("#bulkActionModal").modal("hide");
        });
    },
    async sendToJunk() {
      if (this.bulkSelected.length === 0) {
        toast.error("You haven't selected any emails to send to Junk.");
        return;
      }
      this.$store
        .dispatch(MOVE_TO_JUNK_MULTIPLE, {
          ids: this.bulkSelected.map((x) => x.id).join(",")
        })
        .then(() => {
          this.bulkSelected = [];
          this.getEmails();
          $("#bulkActionModal").modal("hide");
        });
    },
    searchEmails() {
      this.searchView = true;
      this.$emit("updateSearchFolder", this.searchView, this.allFolders);
      this.$store.dispatch(GET_EMAILS, this.listConfig);
    },
    clearSearchText() {
      this.search = "";
      this.searchEmails();
      this.searchView = false;
      this.$emit("updateSearchFolder", this.searchView, this.allFolders);
    },
    clearSearchDates() {
      this.date = null;
      this.searchEmails();
      this.searchView = false;
      this.$emit("updateSearchFolder", this.searchView, this.allFolders);
    },
    clearAllSearchFilters() {
      this.date = null;
      this.search = "";
      this.allFolders = false;
      this.searchEmails();
      this.searchView = false;
      this.$emit("updateSearchFolder", this.searchView, this.allFolders);
    },
    setResultsPerPage() {
      this.listConfig.paginationInformation.currentPage = 1;
      window.sessionStorage.setItem(
        "itemsPerPage",
        this.listConfig.paginationInformation.itemsPerPage.toString()
      );
      this.getEmails();
      this.currentPage = 1;
    },
    toggleDropdown(open: boolean) {
      setTimeout(() => this.dropdownOpen = open, open ? 1 : 200);
    },
    addSearchTerm(term: string) {
      if (this.search.length > 0) {
        this.search += ";";
      }
      this.search += term;
      (this.$refs.search as HTMLElement).focus();

      this.toggleDropdown(false);
    },
    handleClick: function (id: any) {
      const email = this.emails.find((x: any) => x.id === id);
      if (email) {
        email.isReadBySelf = true;
      }
      if (id !== this.emailId) {
        this.$router
          .push({
            name: "inbox",
            params: {
              folderId: this.folderId,
              emailId: id
            }
          })
          .then(() => this.giveUpdatedEmailCount());
      }
    },
    handleBulkSelect: function (item: ISelected) {
      if (this.bulkSelected.some((x) => x.id === item.id)) {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const existing = this.bulkSelected.find((x) => x.id == item.id);
          if (existing == null) {
            break;
          }
          this.bulkSelected.splice(this.bulkSelected.indexOf(existing), 1);
        }
      } else {
        this.bulkSelected.push(item);
      }
    },
    massChangeReadStatus() {
      this.$store
        .dispatch(MARK_SELECTED_READ, {
          ids: this.bulkSelected.map((x) => x.id).join(","),
          markRead: this.bulkSelected.every((x) => x.isReadBySelf === false)
        })
        .then(() => {
          this.bulkSelected = [];
          this.getEmails();
        });
    }
  }
});
export default EmailList;
