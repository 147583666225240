import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c535b20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = ["data-test", "onClick", "textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
      return (_openBlock(), _createElementBlock("a", {
        href: "#",
        "data-test": `page-link-${page}`,
        key: page,
        class: _normalizeClass(_ctx.paginationClass(page)),
        onClick: _withModifiers(($event: any) => (_ctx.changePage(page)), ["prevent"]),
        textContent: _toDisplayString(page)
      }, null, 10, _hoisted_2))
    }), 128))
  ]))
}