/* eslint-disable prefer-spread */
import { POSITION, TYPE, useToast } from "vue-toastification";
import Toast from "./Toast.vue";

const toastService = useToast();

export enum ToastKind {
  info = 0,
  success = 1,
  warning = 2,
  error = 3,
}
export enum ToastPositionKind {
  topLeft = "top-left",
  topCenter = "top-center",
  topRight = "top-right",
  bottomLeft = "bottom-left",
  bottomCenter = "bottom-center",
  bottomRight = "bottom-right"
}
export declare type ToastContent = { message: string, title?: string | undefined | null };

export declare type ToastMessage = string | ToastContent;

export declare type ToastOptions = {
  kind?: ToastKind | null;
  position?: ToastPositionKind | null;
}

export declare type ToastDefinition = ToastOptions & ToastContent;

function resolvePosition(kind: ToastPositionKind): POSITION | undefined {
  switch (kind) {
    case ToastPositionKind.topRight:
      return POSITION.TOP_RIGHT;
    case ToastPositionKind.topLeft:
      return POSITION.TOP_LEFT;
    case ToastPositionKind.topCenter:
      return POSITION.TOP_CENTER;
    case ToastPositionKind.bottomRight:
      return POSITION.BOTTOM_RIGHT;
    case ToastPositionKind.bottomLeft:
      return POSITION.BOTTOM_LEFT;
    case ToastPositionKind.bottomCenter:
      return POSITION.BOTTOM_CENTER;
  }
}

function resolveType(kind: ToastKind): TYPE | undefined {
  switch (kind) {
    case ToastKind.info:
      return TYPE.INFO;
    case ToastKind.warning:
      return TYPE.WARNING;
    case ToastKind.success:
      return TYPE.SUCCESS;
    case ToastKind.error:
      return TYPE.ERROR;
  }
}

function toastFn(message: ToastDefinition)
function toastFn(message: ToastMessage, kind: ToastKind, position?: ToastPositionKind)
function toastFn(message: string | ToastDefinition | ToastMessage, kind?: ToastKind, position?: ToastPositionKind): void {
  const options: ToastDefinition = typeof message === "string"
    ? { message } as ToastDefinition
    : message as ToastDefinition;
  const type = resolveType(kind ?? options.kind ?? ToastKind.info);
  toastService(
    {
      component: Toast,
      props: {
        title: options.title,
        message: options.message,
        type,
      }
    },
    {
      type,
      position: resolvePosition(position ?? options.position ?? ToastPositionKind.topRight),
      bodyClassName: "convert-new-lines"
    });
};

function toastFnInternal(kind: ToastKind, message: string | null, title: string | null, position?: ToastPositionKind);
function toastFnInternal(kind: ToastKind, message: ToastMessage, position?: ToastPositionKind);
function toastFnInternal(kind: ToastKind, message: ToastDefinition)
function toastFnInternal(kind: ToastKind,
  message: ToastDefinition | ToastMessage | string | null,
  titleOrPosition?: string | ToastPositionKind | null,
  position?: ToastPositionKind) {
  if (typeof message === "string") {
    toastFn({
      message,
      title: titleOrPosition,
      position,
      kind
    });
  } else {
    const options = {
      ...message
    } as ToastDefinition;
    options.title = options.title ?? (
      typeof titleOrPosition === "string" ? titleOrPosition : options.title
    );
    options.position = options.position ?? (
      typeof titleOrPosition === "string" ? position : titleOrPosition
    );
    toastFn(options);
  }
}

interface IToast {
  (message: ToastMessage, kind?: ToastKind, position?: ToastPositionKind);
  (message: ToastOptions);
  error(message: string, title?: string, position?: ToastPositionKind);
  error(message: ToastMessage, position?: ToastPositionKind);
  error(message: ToastOptions);
  info(message: string | null, title?: string, position?: ToastPositionKind);
  info(message: ToastMessage, position?: ToastPositionKind);
  info(message: ToastOptions);
  warning(message: string | null, title?: string, position?: ToastPositionKind);
  warning(message: ToastMessage, position?: ToastPositionKind);
  warning(message: ToastOptions);
  success(message: string | null, title?: string, position?: ToastPositionKind);
  success(message: ToastMessage, position?: ToastPositionKind);
  success(message: ToastOptions);
}

function mix(args: any[], kind: ToastKind): any {
  args.unshift(kind);
  return args;
}
const toast = toastFn as IToast;
toast.error = (...args: any[]) => toastFnInternal.apply(null, mix(args, ToastKind.error));
toast.info = (...args: any[]) => toastFnInternal.apply(null, mix(args, ToastKind.info));
toast.warning = (...args: any[]) => toastFnInternal.apply(null, mix(args, ToastKind.warning));
toast.success = (...args: any[]) => toastFnInternal.apply(null, mix(args, ToastKind.success));
export { toast };

