
import { mapGetters } from "vuex";
import JwtService from "@/common/jwt.service";
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  components: {},
  beforeCreate() {
    let isAuthenticated = !!JwtService.getToken();

    if (!isAuthenticated && this.$route.meta.requiresAuth)
      this.$router.push({ name: "login" });
  }
});
