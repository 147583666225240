export function flattenObject(obj: any, separator: string = ".") {
  if (obj == null) {
    return null;
  }

  const toReturn = {} as any;

  for (const property in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, property)) {
      continue;
    }

    if (!(obj[property] instanceof Date) && (typeof obj[property]) == "object" && obj[property] !== null) {
      const flatObject = flattenObject(obj[property]);
      for (const flattenedProperty in flatObject) {
        if (!Object.prototype.hasOwnProperty.call(flatObject, flattenedProperty)) {
          continue;
        }

        toReturn[`${property}${separator}${flattenedProperty}`] = flatObject[flattenedProperty];
      }
    } else {
      toReturn[property] = obj[property];
    }
  }
  return toReturn;
}