import { LOG_ERROR } from "./actions.type";
import { SET_ERROR } from "./mutations.type";

const state = {
  errors: null
};

const getters = { };

const actions = {
  [LOG_ERROR](context, params) {
    context.commit(SET_ERROR, params);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
