import { GET_BULK_RULES, GET_BULK_RULE, CREATE_BULK_RULE, UPDATE_BULK_RULE, DELETE_BULK_RULE, RESET_BULK_RULE } from "./actions.type";
import { SET_BULK_RULES, SET_BULK_RULE, RESET_BULK_RULES } from "./mutations.type";

import { BulkRuleService } from "@/common/api.service";
import { toast } from "../common/toast/toast.service";

export interface IBulkRule {
  id: string;
  name: string;
  field: string;
  pattern: string;
  value: string;
  destinationFolderId: string;
  destinationFolder: any;
}
export interface BulkRulesState {
  bulkRules: Array<IBulkRule>;
  bulkRule: IBulkRule;
  bulkRulesPagination: any;
}
const defaultState = () => {
  return {
    bulkRules: [],
    bulkRule: {
      id: "",
      name: "",
      field: "",
      pattern: "",
      value: "",
      destinationFolderId: "",
      destinationFolder: {}
    },
    bulkRulesPagination: {}
  } as BulkRulesState;
};

const state = defaultState();

const getters = {};

const actions = {
  async [GET_BULK_RULES]({ commit }, params) {
    const response = await BulkRuleService.getBulkRules(params);

    if (typeof (response) === "undefined")
      return;

    commit(SET_BULK_RULES, response.data);
  },
  async [GET_BULK_RULE]({ commit }, params) {
    const response = await BulkRuleService.getBulkRule(params);

    if (typeof (response) === "undefined")
      return;

    commit(SET_BULK_RULE, response.data.result);
  },
  async [CREATE_BULK_RULE](context, params) {
    const response = await BulkRuleService.createBulkRule(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Created rule!");
    }
  },
  async [UPDATE_BULK_RULE](context, params) {
    const response = await BulkRuleService.updateBulkRule(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Updated rule!");
    }
  },
  async [DELETE_BULK_RULE](context, params) {
    const response = await BulkRuleService.deleteBulkRule(params.id);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Deleted rule!");
    }
  },
  [RESET_BULK_RULE]({ commit }) {
    commit(SET_BULK_RULE, null);
  }
};

const mutations = {
  [SET_BULK_RULES](state, response) {
    state.bulkRules = response.result;
    state.bulkRulesPagination = response.paginationInformation;
  },
  [SET_BULK_RULE](state, bulkRule) {
    if (bulkRule)
      state.bulkRule = bulkRule;
    else
      state.bulkRule = {
        id: "",
        name: "",
        field: "",
        pattern: "",
        value: "",
        destinationFolderId: "",
        destinationFolder: {},
        rows: []
      };
  },
  [RESET_BULK_RULES](state) {
    Object.assign(state, defaultState());
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
