import { StoreOptions } from "vuex";
import { StandardService } from "../common/api.service";
import { buildStandardStoreActions, buildState, defaultStandardState, StandardState, StandardStateManager } from "./module-builder";

export interface DomainGreenlistPutPost {
  name: string;
  allowSubdomains?: boolean;
}

export interface DomainGreenlistGetResult {
  id?: string;
  name?: string;
  allowSubdomains?: boolean;
}

const apiRoot = "DomainGreenlist";

export type DomainGreenlistState = StandardState<DomainGreenlistGetResult>;

export const domainGreenlistActions = buildStandardStoreActions(apiRoot);

export default {
  state: defaultStandardState<DomainGreenlistState, DomainGreenlistGetResult>(),
  ...buildState(
    domainGreenlistActions,
    new StandardStateManager<StandardService, DomainGreenlistState, DomainGreenlistGetResult, DomainGreenlistPutPost>(
      new StandardService<any>(apiRoot),
      domainGreenlistActions
    )
  )
} as StoreOptions<any>;
