import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "srcdoc"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", {
    id: _ctx.id,
    srcdoc: _ctx.format(_ctx.html, _ctx.id),
    width: "100%",
    style: {"border":"none"},
    class: "h-full"
  }, null, 8, _hoisted_1))
}