import { StoreOptions } from "vuex";
import { GetPagedRequest } from "../common/api.models";
import ApiService, { StandardService } from "../common/api.service";
import { buildStandardStoreActions, buildState, defaultStandardState, StandardState, StandardStateManager } from "./module-builder";

export interface EmailTemplatesPutPost {
  name: string;
  subject: string;
  body: string;
  kind: EmailTemplateKind;
  isAssignable: boolean;
}

export interface EmailTemplateGetResult {
  id?: string;
  name?: string;
  subject?: string;
  body?: string;
  dateCreated?: string;
  kind?: EmailTemplateKind;
  isAssignable?: boolean;
}

const apiPath = "EmailTemplates";

export type EmailTemplatesState = StandardState<EmailTemplateGetResult>;

export const emailTemplatesActions = buildStandardStoreActions(apiPath);

export enum EmailTemplateKind {
  WelcomeEmail = 1
}

export interface EmailTemplateGetRequest extends GetPagedRequest {
  kind?: EmailTemplateKind;
}
export class EmailTemplatesService extends StandardService<EmailTemplatesPutPost, EmailTemplateGetResult, EmailTemplateGetRequest> {
  constructor() {
    super(apiPath);
  }
  public async getVariables(kind: EmailTemplateKind) {
    ApiService.setHeader();
    return await ApiService.get(`${this.rootApi}/Variables?kind=${kind}`);
  }
}

export default {
  state: defaultStandardState<EmailTemplatesState, EmailTemplateGetResult>(),
  ...buildState(
    emailTemplatesActions,
    new StandardStateManager<EmailTemplatesService, EmailTemplatesState, EmailTemplateGetResult, EmailTemplatesPutPost>(
      new EmailTemplatesService(),
      emailTemplatesActions,
      params => {
        return {
          body: params.body ?? "",
          name: params.name ?? "",
          subject: params.subject ?? "",
          kind: params.kind ?? EmailTemplateKind.WelcomeEmail,
          isAssignable: params.isAssignable ?? false
        };
      }
    )
  )
} as StoreOptions<any>;
