import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import { LOGIN, LOGOUT, REGISTER, CHECK_AUTH, FORGOT_PASSWORD, RESET_PASSWORD, LOG_ERROR } from "./actions.type";
import { SET_AUTH, PURGE_AUTH, SET_ERROR, RESET_FOLDERS, RESET_RULES, RESET_EMAILS, RESET_SEARCH_RESULTS_STATE, RESET_BULK_RULES } from "./mutations.type";

export interface AuthState {
  errors: any[];
  user: any;
  isAuthenticated: boolean;
}

const defaultState = () => {
  return {
    errors: [],
    user: {},
    isAuthenticated: !!JwtService.getToken()
  } as AuthState;
};

const state = defaultState();

const getters = {
  currentUser(state: any) {
    return state.user;
  },
  isAuthenticated(state: any) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context: any, params: any = null) {
    return new Promise(resolve => {
      ApiService.post("Account/Login", params)
        .then(({ data }) => {
          if (data.succeeded === true) {
            context.commit(SET_AUTH, data);
            resolve(data);
          } else {
            context.commit(SET_ERROR, ["You do not have permissions to access this page"]);
          }
        })
        .catch(({ response }) => {
          if (typeof (response) === "undefined")
            context.commit(SET_ERROR, ["Oops, we were unable to log you in.  Please try again and if the issue persists, contact support@corpservvo.com"]);
          else
            context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [FORGOT_PASSWORD](context: any, params: any = null) {
    return new Promise(resolve => {
      ApiService.post("Account/ForgotPassword", params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          if (typeof (response) === "undefined")
            context.commit(SET_ERROR, ["Oops, we were unable to send your password reset email.  Please try again and if the issue persists, contact support@corpservvo.com"]);
          else
            context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [RESET_PASSWORD](context: any, params: any) {
    return new Promise(resolve => {
      ApiService.post("Account/ResetPassword", params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          if (typeof (response) === "undefined")
            context.commit(SET_ERROR, ["Oops, we were unable to reset your account password.  Please try again and if the issue persists, contact support@corpservvo.com"]);
          else
            context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context: any) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context: any, params: any) {
    return new Promise((resolve, reject) => {
      ApiService.post("Account/Register", params)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.result);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [CHECK_AUTH](context: any) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("Account/GetAccountInformation")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(PURGE_AUTH);
          context.commit(RESET_FOLDERS);
          context.commit(RESET_RULES);
          context.commit(RESET_BULK_RULES);
          context.commit(RESET_EMAILS);
          context.commit(RESET_SEARCH_RESULTS_STATE);
        });
    } else {
      context.commit(PURGE_AUTH);
      context.commit(RESET_FOLDERS);
      context.commit(RESET_RULES);
      context.commit(RESET_BULK_RULES);
      context.commit(RESET_EMAILS);
      context.commit(RESET_SEARCH_RESULTS_STATE);
    }
  },
  [LOG_ERROR](context: any, errors: any) {
    context.commit(SET_ERROR, errors);
  }
};

const mutations = {
  [SET_AUTH](state: any, data: any) {
    state.isAuthenticated = true;
    state.user = data.result;
    state.errors = {};
    JwtService.saveToken(data.token);
  },
  [PURGE_AUTH](state: any) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_ERROR](state: any, errors: any) {
    state.errors = errors;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
