
import { defineComponent, PropType } from "vue";
import RenderHtml from "./RenderHtml.vue";

export default defineComponent({
  components: { RenderHtml },
  name: "EmailBody",
  props: {
    subject: {
      type: String
    },
    body: {
      type: String as PropType<string>
    },
    attachments: {
      type: Array,
      default: function () {
        return [];
      }
    },
    isReply: {
      type: Boolean,
      default: false
    }
  }
});
