import { API_URL } from "@/common/config";
import JwtService from "@/common/jwt.service";
import axios, { AxiosRequestConfig } from "axios";
import { GetDomainsQuery, RegisterDomain } from "../store/domains.module";
import { CreateRequest, GetPagedRequest, ItemRequest, UpdateRequest } from "./api.models";
import { PagedOperationResult, PaginatedQuery } from "./contracts/paginated.result";
import { flattenObject } from "./object-flattener.service";
import { toast } from "./toast/toast.service";

const ApiService = {
  init() {
    axios.defaults.baseURL = API_URL;

    axios.interceptors.response.use(
      function (response: any) {
        return response;
      },
      function (error: any) {
        if (error.response?.data?.errors) {
          toast.error(error.response.data.errors.join("\r\n"), "Oops");
        } else {
          toast.error("Something unexpected happened.  If this continues, please contact support.", "Oops");
        }
      });
  },

  setHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
  },

  toConfig(params: any): AxiosRequestConfig {
    params = params == null ? params : JSON.parse(JSON.stringify(params));
    if (params?.params) {
      params.params = flattenObject(params.params);
    }
    return params;
  },

  async get<TGetResponse = any>(resource: string, params: any = undefined) {
    return await axios.get<TGetResponse>(resource, this.toConfig(params));
  },

  async download(resource: string, params: any = undefined) {
    const config = this.toConfig(params);
    config.responseType = "blob";
    const response = await axios.get(resource, config);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "export.csv"); //or any other extension
    document.body.appendChild(link);
    link.click();
  },

  getUri(resource: string, params: any = undefined) {
    return axios.getUri({
      baseURL: axios.defaults.baseURL,
      url: resource,
      params: this.toConfig(params)
    });
  },

  async getReport(resource: any, params: any = undefined) {
    const response = await axios.get(resource, params);

    const byteCharacters = atob(response.data.fileContents);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/csv" });

    const fileURL = window.URL.createObjectURL(blob);
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", response.data.fileDownloadName + ".csv");
    document.body.appendChild(fileLink);

    fileLink.click();
  },

  async post(resource: any, params: any = undefined, headers: any = undefined) {
    return await axios.post(resource, params, { headers });
  },

  async put(resource: any, params: any = undefined) {
    return await axios.put(resource, params);
  },

  async delete(resource: any, params: any = undefined) {
    return await axios.delete(resource, params);
  }
};

export default ApiService;

export const AccountService = {
  get() {
    ApiService.setHeader();
    return ApiService.get("Account/GetAccountInformation");
  }
}

export const SearchService = {
  async search(params: any) {
    ApiService.setHeader();
    return await ApiService.get("Search", { params: params });
  }
}

export const EmailService = {
  async getEmails(params: any) {
    ApiService.setHeader();

    const args = {} as any;

    args.currentPage = params.paginationInformation.currentPage || 1;
    args.itemsPerPage = params.paginationInformation.itemsPerPage || 25;
    args.search = params.search || "";
    args.fromDate = params.fromDate;
    args.toDate = params.toDate;
    args.includeAllFolders = params.includeAllFolders;
    args.propertyToSortBy = params.propertyToSortBy;
    args.isDescending = params.sortIsDescending;

    let url = "Emails";

    if (params.folderId)
      url += "?folderId=" + params.folderId;

    return await ApiService.get(url, { params: args });
  },
  async searchEmails(params: any) {
    ApiService.setHeader();
    return await ApiService.post("Emails", { name: params.name });
  },
  async getEmail(params: any) {
    ApiService.setHeader();

    return await ApiService.get("Emails/" + params.id);
  },
  async moveToFolderMultiple(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Emails/MoveToFolderMultiple?emailIds=" + params.ids + "&folderId=" + params.folderId);
  },
  async changeReadStatus(params: any) {
    ApiService.setHeader();

    return await ApiService.put("Emails/" + params.id + "/ReadStatus", { isRead: params.isRead });
  },
  async markMultipleRead(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Emails/MassMarkAsRead?emailIds=" + params.ids + "&markRead=" + params.markRead);
  },
  async moveToFolder(params: any) {
    ApiService.setHeader();

    return await ApiService.put("Emails/" + params.id + "/Folder", { folderId: params.folderId });
  },
  async moveToJunk(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Emails/MoveToJunk?id=" + params.id + "&blockSender=" + params.blockSender);
  },
  async moveToJunkMultiple(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Emails/MoveToJunkMultiple?emailIds=" + params.ids);
  },
  async deleteEmail(params: any) {
    ApiService.setHeader();

    return await ApiService.delete("Emails/" + params.id);
  },
  async deleteEmailsMultiple(params: any) {
    ApiService.setHeader();
    return await ApiService.delete("Emails/DeleteMultiple?emailIds=" + params.ids);
  },
  async sendEmail(params: any) {
    ApiService.setHeader();

    const formData = new FormData();
    formData.append("to", params.to);
    formData.append("from", params.from);
    formData.append("subject", params.subject);
    formData.append("body", params.body);
    formData.append("isInternal", params.isInternal);

    for (let i = 0; i < params.attachments.length; i++)
      formData.append("attachments[" + i + "]", params.attachments[i], params.attachments[i].name);

    return await axios.post("Emails/", formData, { headers: { "Content-Type": "multipart/form-data" } });
  },
  async sendReply(params: any) {
    ApiService.setHeader();

    const formData = new FormData();
    formData.append("subject", params.subject);
    formData.append("body", params.body);
    formData.append("isInternal", params.isInternal);

    for (let i = 0; i < params.attachments.length; i++)
      formData.append("attachments[" + i + "]", params.attachments[i], params.attachments[i].name);

    return await axios.post("Emails/" + params.emailId + "/Replies", formData, { headers: { "Content-Type": "multipart/form-data" } });
  },
  async sendForward(params: any) {
    ApiService.setHeader();

    const formData = new FormData();
    formData.append("from", params.from);
    formData.append("to", params.to);
    formData.append("subject", params.subject);
    formData.append("body", params.body);
    formData.append("isInternal", params.isInternal);

    for (let i = 0; i < params.attachments.length; i++)
      formData.append("attachments[" + i + "]", params.attachments[i], params.attachments[i].name);

    return await axios.post("Emails/" + params.emailId + "/Forward", formData, { headers: { "Content-Type": "multipart/form-data" } });
  },
  async getRule(params: any) {
    ApiService.setHeader();
    return await ApiService.get("Rules/" + params.ruleId);
  }
}

export const FolderService = {
  async getFolders(params: any) {
    ApiService.setHeader();

    const args = {} as any;

    args.currentPage = params.paginationInformation.currentPage || 1;
    args.itemsPerPage = params.paginationInformation.itemsPerPage || 10;
    args.selectedFolderId = params.selectedFolderId || null;

    return await ApiService.get("Folders", { params: args });
  },
  async getFolder(params: any) {
    ApiService.setHeader();
    return await ApiService.get("Folders/" + params.folderId);
  },
  async createFolder(params: any) {
    ApiService.setHeader();
    return await ApiService.post("Folders", { name: params.name, protected: params.protected });
  },
  async renameFolder(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Folders/" + params.id, { name: params.name, protected: params.protected });
  },
  async deleteFolder(params: any) {
    ApiService.setHeader();
    return await ApiService.delete("Folders/" + params);
  }
}

export class StandardService<TPutPost = any, TGetResponse = any, TGetPagedRequest extends GetPagedRequest = GetPagedRequest> {
  constructor(public rootApi: string) {

  }

  public async getPaged(params: TGetPagedRequest) {
    ApiService.setHeader();

    params.paginationInformation ??= {} as any;
    if (params.paginationInformation != null) {
      params.paginationInformation.currentPage ??= 1;
      params.paginationInformation.itemsPerPage ??= 10;
    }
    params.sortInformation ??= {} as any;
    if (params.sortInformation) {
      params.sortInformation.descending ??= false;
    }

    return await ApiService.get<PagedOperationResult<TGetResponse[]>>(this.rootApi, { params });
  }
  public async get(params: ItemRequest) {
    ApiService.setHeader();
    return await ApiService.get(`${this.rootApi}/${params.id}`);
  }
  public async create(params: CreateRequest<TPutPost>) {
    ApiService.setHeader();
    return await ApiService.post(this.rootApi, params.model);
  }
  public async update(params: UpdateRequest<TPutPost>) {
    ApiService.setHeader();
    return await ApiService.put(`${this.rootApi}/${params.id}`, params.model);
  }
  public async delete(params: ItemRequest) {
    ApiService.setHeader();
    return await ApiService.delete(`${this.rootApi}/${params.id}`);
  }
}

export const UsersService = {
  async getUsers(params: any) {
    ApiService.setHeader();
    const args = {} as any;

    args.searchText = params.searchText;
    args.currentPage = params.paginationInformation.currentPage || 1;
    args.itemsPerPage = params.paginationInformation.itemsPerPage || 10;
    args.selectedUserId = params.selectedUserId || null;

    return await ApiService.get("Users", { params: args });
  },
  async getUser(params: any) {
    ApiService.setHeader();
    return await ApiService.get("Users/" + params.id);
  },
  async createUser(params: any) {
    ApiService.setHeader();

    if (params.id == null)
      params.id = "";

    if (params.email == null || params.email === "")
      params.email = params.contact.emailAddress;

    return await ApiService.post("Users", params);
  },
  async updateUser(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Users/" + params.id, params);
  },
  async deleteUser(params: any) {
    ApiService.setHeader();
    return await ApiService.delete("Users/" + params);
  },
  async sendResetPassword(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Users/" + params.id + "/SendResetPassword");
  },
  async generateResetPasswordLink(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Users/" + params.id + "/GenerateResetPasswordLink");
  }
};

export const RuleService = {
  async getRules(params: any) {
    ApiService.setHeader();

    const args = {} as any;

    args.currentPage = params.paginationInformation.currentPage || 1;
    args.itemsPerPage = params.paginationInformation.itemsPerPage || 10;
    args.selectedRuleId = params.selectedRuleId || null;
    args.type = params.type || 0;

    return await ApiService.get("Rules", { params: args });
  },
  async getRule(params: any) {
    ApiService.setHeader();
    return await ApiService.get("Rules/" + params.ruleId);
  },
  async createRule(params: any) {
    ApiService.setHeader();
    return await ApiService.post("Rules", {
      name: params.name,
      field: params.field,
      pattern: params.pattern,
      value: params.value,
      destinationFolderId: params.destinationFolderId
    });
  },
  async updateRule(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Rules/" + params.id, {
      name: params.name,
      field: params.field,
      pattern: params.pattern,
      value: params.value,
      destinationFolderId: params.destinationFolderId
    });
  },
  async deleteRule(params: any) {
    ApiService.setHeader();
    return await ApiService.delete("Rules/" + params);
  },
  async blockSender(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Rules/BlockSender?emailAddress=" + encodeURIComponent(params.emailAddress));
  }
}

export const BulkRuleService = {
  async getBulkRules(params: any) {
    ApiService.setHeader();

    const args = {} as any;

    args.currentPage = params.paginationInformation.currentPage || 1;
    args.itemsPerPage = params.paginationInformation.itemsPerPage || 10;
    args.selectedRuleId = params.selectedRuleId || null;

    return await ApiService.get("BulkRules", { params: args });
  },
  async getBulkRule(params: any) {
    ApiService.setHeader();
    return await ApiService.get("BulkRules/" + params.ruleId);
  },
  async createBulkRule(params: any) {
    ApiService.setHeader();
    return await ApiService.post("BulkRules", {
      name: params.selectedRule.name,
      rows: params.selectedRule.rows,
      destinationFolderId: params.selectedRule.destinationFolderId,
      originFolderId: params.selectedRule.originFolderId,
      executeRule: params.executeRule
    });
  },
  async updateBulkRule(params: any) {
    ApiService.setHeader();
    return await ApiService.put("BulkRules/" + params.selectedRule.id, {
      name: params.selectedRule.name,
      rows: params.selectedRule.rows,
      destinationFolderId: params.selectedRule.destinationFolderId,
      originFolderId: params.selectedRule.originFolderId,
      executeRule: params.executeRule
    });
  },
  async deleteBulkRule(params: any) {
    ApiService.setHeader();
    return await ApiService.delete("BulkRules/" + params);
  }
}

export const DomainsService = {
  async getPendingDomains(): Promise<any> {
    ApiService.setHeader();

    return await ApiService.getReport("Domains/DownloadPendingDomains");
  },
  async getIncompleteDomains(): Promise<any> {
    ApiService.setHeader();

    return await ApiService.getReport("Domains/DownloadIncompleteDomains");
  },
  async uploadDomains(params: any) {
    ApiService.setHeader();

    const formData = new FormData();
    formData.append("file", params);

    return await ApiService.post("Domains/FileUpload", formData, { headers: { "Content-Type": "multipart/form-data" } });
  },
  async registerDomain(params: RegisterDomain) {
    ApiService.setHeader();
    return await ApiService.post("Domains/RegisterDomain", params);
  },
  async getDomains(params: PaginatedQuery<GetDomainsQuery>) {
    ApiService.setHeader();
    const args = {} as any;

    args.currentPage = params.paginationInformation.currentPage || 1;
    args.itemsPerPage = params.paginationInformation.itemsPerPage || 10;
    args.query = params.query;

    return await ApiService.get("Domains", { params: args });
  },
  async getDomainCounts() {
    ApiService.setHeader();
    return await ApiService.get("Domains/Counts");
  },
  async updateDomain(params: any) {
    ApiService.setHeader();
    return await ApiService.put("Domains/" + params);
  },
  async deleteDomain(params: any) {
    ApiService.setHeader();
    return await ApiService.delete("Domains/" + params);
  }
}
