
import { mapState } from "vuex";
import { LOGIN, LOG_ERROR } from "@/store/actions.type";
import { defineComponent, State } from "vue";

export default defineComponent({
  name: "CSLogin",
  data() {
    return {
      username: null,
      password: null
    };
  },
  methods: {
    onSubmit(username, password) {
      this.$store
        .dispatch(LOGIN, { username, password })
        .then(() => {
          this.$router.push({ name: "inbox" });
        });
    }
  },
  computed: {
    ...mapState({
      errors: state => (state as State).auth.errors
    })
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch(LOG_ERROR, []);

    next();
  }
});
