import { SearchService } from "@/common/api.service";

import { SET_SEARCH_RESULTS, RESET_SEARCH_RESULTS_STATE } from "./mutations.type";

import { FETCH_SEARCH_RESULTS } from "./actions.type";

export interface SearchResultsState {
  searchResultsPagination: any;
  companies: Array<any>;
  users: Array<any>;
}

const defaultState = () => {
  return {
    searchResultsPagination: {},
    companies: [],
    users: []
  } as SearchResultsState;
};

const state = defaultState();

const getters = { };

const actions = {
  async [FETCH_SEARCH_RESULTS]({ commit }, params) {
    const {data} = await SearchService.search(params);

    commit(SET_SEARCH_RESULTS, data);
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_SEARCH_RESULTS](state, data) {
    state.companies = data.result.companies;
    state.users = data.result.users
    state.searchResultsPagination = data.paginationInformation;
  },
  [RESET_SEARCH_RESULTS_STATE](state) {
    Object.assign(state, defaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
