
import { mapState } from "vuex";
import { defineComponent, State } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faInbox,
  faCog,
  faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-regular-svg-icons";

library.add(faInbox, faFolder, faCog, faSignOutAlt);

import { GET_FOLDERS, RESET_FOLDER } from "../store/actions.type";
import { Folder } from "@/store/folders.module";

const MainNavigation = defineComponent({
  name: "MainNavigation",
  components: {},
  props: {
    folderId: {
      type: String
    },
    emailId: {
      type: String
    },
    navRender: {
      type: Number
    },
    currentlySearching: {
      type: Boolean
    },
    searchAllFolders: {
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      folders: (state) => (state as State).folders.folders,
      folder: (state) => (state as State).folders.folder
    })
  },
  methods: {
    // Keep logic in one place in case we need to tweak it
    // in the future
    resolveRouteForCustomFolder(folder: Folder) {
      return folder.name.toLowerCase();
    },
    isSelected(folder: Folder) {
      return (
        folder.id == this.folderId ||
        (folder.custom &&
          this.$route.name === this.resolveRouteForCustomFolder(folder))
      );
    },
    goToFolder(folder: Folder) {
      if (folder.custom) {
        this.$router.push({
          name: this.resolveRouteForCustomFolder(folder)
        });
      } else {
        this.$router.push({
          name: "inbox",
          params: {
            folderId: folder.id
          }
        });
      }
    },
    getFolders() {
      this.$store.dispatch(RESET_FOLDER);
      this.$store.dispatch(GET_FOLDERS, {
        paginationInformation: { itemsPerPage: 9999999 }
      });
    },
    logout() {
      this.$router.push({ name: "logout" });
    }
  },
  async mounted() {
    try {
      this.getFolders();
      this.error = "";
    } catch (e) {
      this.error = e;
    }
  },
  data() {
    return {
      error: null as any,
      unreadCount: 0
    };
  },
  sortFolders: function (arr) {
    function compare(a, b) {
      if (a.ordinal < b.ordinal) return -1;
      if (a.ordinal > b.ordinal) return 1;
      return 0;
    }

    return arr.sort(compare);
  }
});
export default MainNavigation;
