import { MessageDirectionKind, MessageMethodKind, MessageSourceKind } from "../store/emails.module";
import { EmployeeEventActionErrorKind } from "../store/error-log.module";
import { Enum } from "./contracts/enum-parser";

export class EnumService {
  public getActionReason(kind?: EmployeeEventActionErrorKind) {
    return Enum.GetFriendlyName(EmployeeEventActionErrorKind, kind);
  }
  public getDirection(kind?: MessageDirectionKind) {
    return Enum.GetFriendlyName(MessageDirectionKind, kind);
  }
  public getMethod(kind?: MessageMethodKind) {
    return Enum.GetFriendlyName(MessageMethodKind, kind);
  }
  public getSource(kind?: MessageSourceKind) {
    return Enum.GetFriendlyName(MessageSourceKind, kind);
  }
}
