import { StoreOptions } from "vuex";
import { StandardService } from "../common/api.service";
import { buildStandardStoreActions, buildState, defaultStandardState, StandardState, StandardStateManager } from "./module-builder";
import { AutoReplyEmailDomain } from "./domains.module";

export interface AutoReplyPutPost {
  name: string;
  subject: string;
  body: string;
  includeDomains: string[];
  excludeDomains: string[];
}

export interface AutoReplyGetResult {
  id?: string;
  name?: string;
  subject?: string;
  body?: string;
  dateCreated?: string;
  includeDomains?: Array<AutoReplyEmailDomain>;
  excludeDomains?: Array<AutoReplyEmailDomain>;
}

export type AutoRepliesState = StandardState<AutoReplyGetResult>;

export const autoReplyActions = buildStandardStoreActions("AutoReplies");

export default {
  state: defaultStandardState<AutoRepliesState, AutoReplyGetResult>(),
  ...buildState(
    autoReplyActions,
    new StandardStateManager<StandardService, AutoRepliesState, AutoReplyGetResult, AutoReplyPutPost>(
      new StandardService<any>("AutoReplies"),
      autoReplyActions,
      params => {
        return {
          body: params.body ?? "",
          name: params.name ?? "",
          subject: params.subject ?? "",
          excludeDomains: (params.excludeDomains ?? []).map(_ => _.name),
          includeDomains: (params.includeDomains ?? []).map(_ => _.name),
        };
      }
    )
  )
} as StoreOptions<any>;
