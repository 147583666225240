import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vh-100 grid pulse-main-layout max-w-full overflow-hidden" }
const _hoisted_2 = { class: "border-right min-h-full pulse-inbox" }
const _hoisted_3 = { class: "overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavigation = _resolveComponent("MainNavigation")!
  const _component_EmailList = _resolveComponent("EmailList")!
  const _component_Email = _resolveComponent("Email")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_component_MainNavigation, {
      folderId: this.resolvedFolderId,
      key: _ctx.navRender,
      currentlySearching: this.currentlySearching,
      searchAllFolders: this.searchAllFolders
    }, null, 8, ["folderId", "currentlySearching", "searchAllFolders"])),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_EmailList, {
        folderId: this.resolvedFolderId,
        onUpdatedEmailCount: _ctx.giveUpdatedEmailCount,
        onUpdateSearchFolder: _ctx.updateSearchFolder,
        onSetCurrentPage: _ctx.setCurrentPage,
        isProtected: _ctx.currentFolder()?.protected === true,
        canDeleteEmailsIfProtected: _ctx.currentFolder()?.canDeleteEmailsIfProtected === true,
        emailSearch: _ctx.emailSearch
      }, null, 8, ["folderId", "onUpdatedEmailCount", "onUpdateSearchFolder", "onSetCurrentPage", "isProtected", "canDeleteEmailsIfProtected", "emailSearch"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Email, {
        onUpdatedEmailCount: _ctx.giveUpdatedEmailCount,
        onSearchEmail: _ctx.searchEmail,
        currentPage: this.currentPage
      }, null, 8, ["onUpdatedEmailCount", "onSearchEmail", "currentPage"])
    ])
  ]))
}