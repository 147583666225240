import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { SearchResultsState } from "@/store/searchresults.module";
import ApiService from "@/common/api.service";
import axios from "axios";
import { createApp } from "vue";
import VueAxios from "vue-axios";
import { Store } from "vuex";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import { AuthState } from "@/store/auth.module";
import { EmailsState } from "@/store/emails.module";
import { FoldersState } from "@/store/folders.module";
import { AutoRepliesState } from "@/store/auto-replies.module";
import { CompaniesState } from "@/store/companies.module";
import { DomainGreenlistState } from "@/store/domain-greenlist.module";
import { SentEmailsState } from "@/store/sent-emails.module";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { RulesState } from "@/store/rules.module";
import { BulkRulesState } from "@/store/bulkRules.module";
import { DomainsState } from "@/store/domains.module";
import { UsersState } from "@/store/users.module";
import { EmailTemplatesState } from "@/store/email-templates.module";
import Toast, { PluginOptions } from "vue-toastification";
import dateTime from "@/common/datetime.filter";
import dateTimeRelative from "@/common/datetime-relative.filter";
import PrimeVue from "primevue/config";
import CKEditor from "@ckeditor/ckeditor5-vue";

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "@/index.css";
import "@/bootstrap.css";
import { ErrorLogState } from "./store/error-log.module";

declare module "@vue/runtime-core" {
    // Declare your own store states.
    interface State {
        auth: AuthState;
        emails: EmailsState;
        folders: FoldersState;
        autoReplies: AutoRepliesState;
        emailTemplates: EmailTemplatesState;
        rules: RulesState;
        bulkRules: BulkRulesState;
        domains: DomainsState;
        users: UsersState;
        searchResults: SearchResultsState;
        companies: CompaniesState;
        sentEmails: SentEmailsState;
        errorLog: ErrorLogState;
        domainGreenlist: DomainGreenlistState;
    }

    interface ComponentCustomProperties {
        $store: Store<State>;
    }
}

const toastOptions: PluginOptions = {};

const app = createApp(App);
app
    .use(store)
    .use(router)
    .use(CKEditor)
    .use(Toast, toastOptions)
    .use(PrimeVue)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(VueAxios, axios)
    .provide("axios", app.config.globalProperties.axios)
    .mount("#app");

app.config.globalProperties.$filters = {
    dateTime,
    dateTimeRelative
};

ApiService.init();
