
import { defineComponent } from "vue";
import { LOGOUT } from "../store/actions.type";

/*
We use this interim logout page so that the app doesn't try to load various
bits of data during the logout process - this wil show a very simple "Logging out..."
message with no other components loaded whatsoever to try to load data.

In all likelihood nobody will ever see it because it's so fast.

It is to solve this issue:
https://awsmtech.atlassian.net/browse/PUL-85
*/
export default defineComponent({
  name: "CSLogout",
  mounted() {
    this.$store.dispatch(LOGOUT).then(() => {
      this.$router.push({ name: "login" });
    });
  }
});
