import { Folder } from "./folders.module";
import { GET_EMAILS, GET_EMAIL, MOVE_TO_FOLDER, MOVE_TO_JUNK, MOVE_TO_JUNK_MULTIPLE, MOVE_SELECTED_TO_FOLDER, SEND_EMAIL, SEND_REPLY, SEND_FORWARD, DELETE_EMAIL, DELETE_MULTIPLE_EMAILS, RESET_EMAIL, MARK_SELECTED_READ } from "./actions.type";
import { SET_EMAILS, SET_EMAIL, RESET_EMAILS } from "./mutations.type";

import { EmailService } from "@/common/api.service";
import { toast } from "../common/toast/toast.service";
import { PaginationInformation } from "../common/contracts/paginated.result";

export interface Attachment {
  name?: string;
}

export enum MessageDirectionKind {
  Inbound = 1,
  Outbound = 2,
}

export enum MessageMethodKind {
  Manual = 1,
  Automated = 2,
}

export enum MessageSourceKind {
  User = 1,
  AutoReply = 2,
  WelcomeEmail = 3
}

export interface Email {
  id?: string;
  to?: string;
  subject?: string;
  from?: string;
  body?: string;
  folderId?: string;
  replies?: Email[];
  isReadBySelf?: boolean;
  dateCreated?: string;
  folder?: Folder;
  attachments?: Attachment[];
  direction?: MessageDirectionKind;
  method?: MessageMethodKind;
  source?: MessageSourceKind;
  replyFromAddress?: string;
}

export interface EmailsState {
  emails: Array<Email>;
  email: Email;
  emailsPagination: PaginationInformation;
}

const defaultState = () => {
  return {
    emails: [],
    email: {},
    emailsPagination: {} as PaginationInformation
  } as EmailsState;
};

const state = defaultState();

const getters = {};

export interface MoveToJunkModel {
  id: string;
  blockSender: boolean;
}

const actions = {
  async [GET_EMAILS](context: any, params: any = null) {
    const response = await EmailService.getEmails(params);
    if (typeof (response) === "undefined")
      return;

    context.commit(SET_EMAILS, response.data);
  },
  async [GET_EMAIL](context: any, params: any = null) {
    const response = await EmailService.getEmail(params);
    if (typeof (response) === "undefined")
      return;

    context.commit(SET_EMAIL, response.data.result);
    return response.data.result.isReadBySelf;

  },
  async [MOVE_SELECTED_TO_FOLDER](context: any, params: any = null) {
    const response = await EmailService.moveToFolderMultiple(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Moved emails to destination folder!");
    }
  },
  async [MOVE_TO_FOLDER](context: any, params: any = null) {
    const response = await EmailService.moveToFolder(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Moved to folder!");
    }
  },
  async [MOVE_TO_JUNK](context: any, params: MoveToJunkModel | null = null) {
    const response = await EmailService.moveToJunk(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", `Moved to Junk.${params?.blockSender ? " Sender blocked." : ""}`);
    }

    return response.status == 200;
  },
  async [MOVE_TO_JUNK_MULTIPLE](context: any, params: any = null) {
    const response = await EmailService.moveToJunkMultiple(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Moved to Junk. Senders blocked.!");
    }
  },
  async [SEND_EMAIL](context: any, params: any) {
    const response = await EmailService.sendEmail(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Email sent!");
    }
    return response;
  },
  async [SEND_REPLY](context: any, params: any = null) {
    const response = await EmailService.sendReply(params);

    if (typeof (response) === "undefined")
      return false;

    if (response.data.succeeded === true) {
      toast.success(" ", "Reply sent!");
    }

    return response.data.succeeded === true;
  },
  async [SEND_FORWARD](context: any, params: any = null) {
    const response = await EmailService.sendForward(params);

    if (typeof (response) === "undefined")
      return false;

    if (response.data.succeeded === true) {
      toast.success(" ", "Forward sent!");
    }

    return response.data.succeeded === true;
  },
  async [DELETE_EMAIL](context: any, params: any = null) {
    const response = await EmailService.deleteEmail(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Email deleted!");
    }
  },
  async [DELETE_MULTIPLE_EMAILS](context: any, params: any = null) {
    const response = await EmailService.deleteEmailsMultiple(params);
    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Emails deleted!");
    }
  },

  [RESET_EMAIL](context: any) {
    context.commit(SET_EMAIL, {});
  },
  async [MARK_SELECTED_READ](context: any, params: any = null) {
    await EmailService.markMultipleRead(params);
  }
};

const mutations = {
  [SET_EMAILS](state: any, response: any) {
    state.emails = response.result;
    state.emailsPagination = response.paginationInformation;
  },
  [SET_EMAIL](state: any, email: any) {
    state.email = email;
  },
  [RESET_EMAILS](state: any) {
    Object.assign(state, defaultState());
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
