
import { defineComponent, State } from "vue";

import MainNavigation from "@/components/MainNavigation.vue";
import EmailList from "@/components/EmailList.vue";
import Email from "@/components/Email.vue";
import { mapState } from "vuex";
import { Folder } from "@/store/folders.module";

export default defineComponent({
  name: "CSInbox",
  props: {
    folderId: {
      type: String
    },
    isProtected: {
      type: Boolean
    }
  },
  data() {
    return {
      navRender: 0,
      currentlySearching: false,
      emailSearch: "",
      currentPage: 1,
      searchAllFolders: false
    };
  },
  components: {
    MainNavigation,
    EmailList,
    Email
  },
  methods: {
    giveUpdatedEmailCount() {
      this.navRender += 1;
    },
    updateSearchFolder(currentlySearching, searchAllFolders) {
      this.currentlySearching = currentlySearching;
      this.searchAllFolders = searchAllFolders;
    },
    searchEmail(value) {
      this.currentlySearching = true;
      this.emailSearch = value;
    },
    setCurrentPage(currentPage) {
      this.currentPage = currentPage;
    },
    currentFolder(): Folder | undefined {
      return this.folders.folders.find((_) => _.id === this.folderId);
    }
  },
  computed: {
    resolvedFolderId: function () {
      return (this.folderId ?? "").trim() === ""
        ? "c57e0125-aa61-4c63-a26f-1147aac07700"
        : this.folderId;
    },
    ...mapState({
      folders: (state) => (state as State).folders
    })
  }
});

//  import { mapState } from "vuex";
//  import Vue from "vue";
//  import MainNavigation from "../components/MainNavigation";
//  import EmailList from "../components/EmailList";
//  import Email from "../components/Email";
//  export default {
//    name: "CSInbox",
//    components: {
//      MainNavigation,
//      EmailList,
//      Email
//    },
//    props: {
//       itemsPerPage: {
//         type: Number,
//         required: false,
//         default: parseInt(window.sessionStorage.getItem("itemsPerPage")) || 25
//       },
//      folderId: {
//        type: String,
//        default: "c57e0125-aa61-4c63-a26f-1147aac07700"
//      }
//       emailId: {
//         type: String
//       }
//    },
//     data() {
//       return {
//     currentPage: 1,
//     editor: ClassicEditor,
//     editorConfig: {},
//     uploadedFiles: [],
//     uploadError: null,
//     currentStatus: null,
//     uploadFieldName: "attachments",
//     dropdownOpen: false,
//     setAllSelected: false,
//     pendingReply: {
//       subject: "",
//       body: "",
//       attachments: [],
//       isInternal: false
//     },
//     pendingEmail: {
//       to: "",
//       from: "",
//       subject: "",
//       body: "",
//       attachments: [],
//       isInternal: false
//     },
//     search: "",
//     sender: "",
//     recipient: "",
//     subject: "",
//     selectedEmails: [],
//     modalActionName: "",
//     bulkTargetFolderId: "",
//     loading: true,
//     error: ""
//       };
//     },
//     async mounted() {
//     this.error = "";
//     this.loading = true;
//     this.reset();
//     try {
//       this.getFolders();
//       this.getEmails();
//       this.error = "";
//     } catch (e) {
//       this.error = e;
//     }
//     },
//    computed: {
//      ...mapState({
//         folders: (state) => state.folders.folders,
//        folder: (state) => state.folders.folder
//         emails: (state) => state.emails.emails,
//         email: (state) => state.emails.email,
//         emailsPagination: (state) => state.emails.emailsPagination
//      })
//       listConfig() {
//         const filters = {
//           paginationInformation: {
//             currentPage: this.currentPage,
//             itemsPerPage: window.sessionStorage.getItem("itemsPerPage") || 25
//           },
//           search: this.search,
//           sender: this.sender,
//           recipient: this.recipient,
//           subject: this.subject
//         };
//         filters.folderId = this.folderId;
//         if (this.$route.params.emailId) {
//           filters.selectedEmailId = this.$route.params.emailId;
//         }
//         return filters;
//       },
//       pages() {
//         if (
//           this.loading ||
//           this.emailsPagination.totalResults <= this.itemsPerPage
//         ) {
//           return [1];
//         }
//         const currentPages = Array.from(
//           new Array(5),
//           (x, i) => i + (this.listConfig.paginationInformation.currentPage - 2)
//         );
//         const maxPages = Array.from(
//           new Array(1),
//           (x, i) => i + this.emailsPagination.totalPages
//         );
//         const minPages = Array.from(new Array(1), (x, i) => i + 1);
//         const allPages = [
//           ...new Set([...minPages, ...currentPages, ...maxPages])
//         ].filter((x) => x > 0 && x <= this.emailsPagination.totalPages);
//         const returnPages = [];
//         for (var i = 0; i < allPages.length; i++) {
//           returnPages.push(allPages[i]);
//           if (allPages[i + 1] - allPages[i] > 1) {
//             returnPages.push("...");
//           }
//         }
//         return returnPages;
//       },
//       isInitial() {
//         return this.currentStatus === STATUS_INITIAL;
//       },
//       isSaving() {
//         return this.currentStatus === STATUS_SAVING;
//       },
//       isSuccess() {
//         return this.currentStatus === STATUS_SUCCESS;
//       },
//       isFailed() {
//         return this.currentStatus === STATUS_FAILED;
//       }
//    }
//     watch: {
//     currentPage(newValue) {
//       this.getEmails();
//     },
//     "$route.params.folderId": {
//       handler(newVal, oldVal) {
//         if (oldVal !== newVal) {
//           this.getEmails();
//           this.currentPage = 1;
//         }
//       },
//       immediate: true
//     },
//     "$route.params.currentPage": {
//       handler(newVal, oldVal) {
//         if (oldVal !== newVal) {
//           this.currentPage = newVal;
//         }
//       },
//       immediate: true
//     },
//     "$route.params.emailId": {
//       handler(newVal, oldVal) {
//         if (!newVal) this.$store.dispatch(RESET_EMAIL);
//         else if (oldVal !== newVal) this.getEmail(newVal);
//       },
//       immediate: true
//     },
//     setAllSelected(newValue) {
//       this.setAllEmailsSelected(newValue);
//     }
//     },
//     methods: {
//     getFolders() {
//       this.$store.dispatch(RESET_FOLDER);
//       this.$store.dispatch(GET_FOLDERS, {
//         paginationInformation: { itemsPerPage: 9999999 }
//       });
//     },
//     setAllEmailsSelected(newValue) {
//       this.selectedEmails =
//         newValue === true ? _.map(this.emails, (email) => email.id) : [];
//     },
//     getEmails() {
//       this.loading = true;
//       this.$store.dispatch(RESET_EMAIL);
//       this.$store.dispatch(GET_EMAILS, this.listConfig).then((x) => {
//         this.loading = false;
//         this.setAllSelected = false;
//         this.setAllEmailsSelected(false);
//         this.bulkTargetFolderId = "";
//       });
//     },
//     getEmail(id, toggle = false) {
//       var eml = this.email || { id: "" };
//       if (toggle === true && (id === "" || this.email.id === id)) {
//         this.$store.dispatch(RESET_EMAIL);
//       } else {
//         this.$store.dispatch(GET_EMAIL, { id: id });
//       }
//     },
//     changeReadStatus(isRead) {
//       this.$store.dispatch(CHANGE_READ_STATUS, {
//         id: this.email.id,
//         isRead: isRead
//       });
//     },
//     moveToFolder() {
//       this.$store.dispatch(MOVE_TO_FOLDER, {
//         id: this.email.id,
//         folderId: this.email.folderId
//       });
//     },
//     async moveToFolderMultiple() {
//       if (this.selectedEmails.length === 0) {
//         Vue.prototype.toast.error(
//           "You haven't selected any emails to move.",
//           "",
//           { position: "topRight" }
//         );
//         return;
//       }
//       if (this.bulkTargetFolderId === "") {
//         Vue.prototype.toast.error(
//           "Please select a folder to move selected emails to",
//           "",
//           { position: "topRight" }
//         );
//         return;
//       }
//       this.$store
//         .dispatch(MOVE_SELECTED_TO_FOLDER, {
//           ids: this.selectedEmails.join(","),
//           folderId: this.bulkTargetFolderId
//         })
//         .then(() => {
//           this.selectedEmails = [];
//           this.getEmails();
//           $("#bulkActionModal").modal("hide");
//         });
//     },
//     async deleteEmails() {
//       if (this.selectedEmails.length === 0) {
//         Vue.prototype.toast.error(
//           "You haven't selected any emails to delete.",
//           "",
//           { position: "topRight" }
//         );
//         return;
//       }
//       this.$store
//         .dispatch(DELETE_MULTIPLE_EMAILS, {
//           ids: this.selectedEmails.join(",")
//         })
//         .then(() => {
//           this.selectedEmails = [];
//           this.getEmails();
//           $("#bulkActionModal").modal("hide");
//         });
//     },
//     sendEmail() {
//       this.$store.dispatch(SEND_EMAIL, this.pendingEmail);
//       this.reset();
//     },
//     sendReply() {
//       this.pendingReply.emailId = this.email.id;
//       this.pendingReply.subject = this.email.subject;
//       this.$store.dispatch(SEND_REPLY, this.pendingReply);
//       this.reset();
//     },
//     searchEmails() {
//       this.$store.dispatch(RESET_EMAIL);
//       this.$store.dispatch(GET_EMAILS, this.listConfig);
//     },
//     clearSearch() {
//       this.subject = "";
//       this.recipient = "";
//       this.sender = "";
//       this.searchEmails();
//     },
//     setResultsPerPage() {
//       window.sessionStorage.setItem(
//         "itemsPerPage",
//         this.listConfig.paginationInformation.itemsPerPage
//       );
//       this.getEmails();
//     },
//     reset() {
//       this.currentStatus = STATUS_INITIAL;
//       this.uploadedFiles = [];
//       this.uploadError = null;
//       this.pendingEmail = {
//         to: "",
//         from: "",
//         subject: "",
//         body: "",
//         attachments: [],
//         isInternal: false
//       };
//       this.pendingReply = {
//         subject: "",
//         body: "",
//         attachments: [],
//         isInternal: false
//       };
//     },
//     filesChange(fieldName, fileList) {
//       if (!fileList.length) return;
//       this.pendingReply.attachments = fileList;
//     },
//     toggleDropdown() {
//       setTimeout(() => (this.dropdownOpen = !this.dropdownOpen), 100);
//     },
//     addSearchTerm(term) {
//       if (this.search.length > 0) {
//         this.search += ";";
//       }
//       this.search += term;
//       this.$refs.search.focus();
//     },
//       openModal(action) {
//         if (this.selectedEmails.length === 0) {
//           Vue.prototype.toast.error(
//             "You haven't selected any emails to action.",
//             "",
//             { position: "topRight" }
//           );
//           return;
//         }
//         this.modalActionName = action;
//         $("#bulkActionModal").modal("show");
//       }
//     }
//     beforeRouteLeave(to, from, next) {
//       if (!to.params.emailId) this.$store.dispatch(RESET_EMAIL);
//       next();
//     },
//     sortFolders: function (arr) {
//       function compare(a, b) {
//         if (a.ordinal < b.ordinal) return -1;
//         if (a.ordinal > b.ordinal) return 1;
//         return 0;
//       }
//       return arr.sort(compare);
//     }
//  };
