
import { defineComponent } from "vue";
import { GuidService } from "@/common/guid.service";

export default defineComponent({
  name: "EmailBody",
  data() {
    return {
      id: `iframe_${GuidService.New()}`
    };
  },
  mounted() {
    // TODO: Destruct
    window.addEventListener(
      "message",
      (e) => {
        const eventName = e.data[0];
        switch (eventName) {
          case "setHeight": {
            const id = e.data[2];
            if (id === this.id) {
              const data = e.data[1];
              const $iframe = document.getElementById(id);
              if ($iframe) {
                $iframe.style.height = `${data}px`;
                this.$emit("size-changed", data);
              }
            }
            break;
          }
        }
      },
      false
    );
  },
  methods: {
    format(body: string, id: string) {
      return `      
<script type="text/javascript">
function resizeIFrame() {
  var target = document.getElementsByTagName("html")[0];
  var height = target.scrollHeight;
  window.parent.postMessage(["setHeight", height, "${id}"], "*"); 
  var observer = new ResizeObserver(function(entries) {
    for (var entry of entries) {
      if(entry.contentBoxSize) {
        // Firefox implements contentBoxSize as a single content rect, rather than an array
        const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
        window.parent.postMessage(["setHeight", contentBoxSize.blockSize, "${id}"], "*"); 
      } else {
        window.parent.postMessage(["setHeight", entry.contentRect.height, "${id}"], "*"); 
      }
    }
  });
  observer.observe(target);
}
</${"script"}>
<body style='font-family: Verdana, sans-serif; overflow: hidden;' onLoad="resizeIFrame();">
      ${body ?? ""}</body>`;
    }
  },
  props: {
    html: {
      type: String
    }
  }
});
