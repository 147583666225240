export const SET_AUTH = "setAuth";
export const PURGE_AUTH = "logOut";
export const SET_ERROR = "setError";

export const SET_EMAILS = "setEmails";
export const SET_EMAIL = "setEmail";
export const RESET_EMAILS = "resetEmails";

export const SET_FOLDERS = "setFolders";
export const SET_FOLDER = "setFolder";
export const RESET_FOLDERS = "resetFolders";

export const SET_RULES = "setRules";
export const SET_RULE = "setRule";
export const RESET_RULES = "resetRules";

export const SET_USERS = "setUsers";
export const SET_USER = "setUser";
export const RESET_USERS_STATE = "resetUsersState";

export const SET_BULK_RULES = "setBulkRules";
export const SET_BULK_RULE = "setBulkRule";
export const RESET_BULK_RULES = "resetBulkRules";

export const SET_SEARCH_RESULTS = "setSearchResults";
export const RESET_SEARCH_RESULTS_STATE = "resetSearchResults";

export const SET_DOMAINS = "setDomains";
export const SET_DOMAIN = "setDomain";
export const RESET_DOMAINS = "resetDomains";

export const SET_ALERT = "setAlert";