import { DomainsService } from "@/common/api.service";
import { PaginatedQuery } from "@/common/contracts/paginated.result";
import { toast } from "@/common/toast/toast.service";
import { DELETE_DOMAIN, GET_DOMAINS, GET_DOMAIN_COUNTS, GET_INCOMPLETE_DOMAINS, GET_PENDING_DOMAINS, REGISTER_DOMAIN, RESET_DOMAIN, UPDATE_DOMAIN, UPLOAD_DOMAINS } from "./actions.type";
import { RESET_DOMAINS, SET_DOMAIN, SET_DOMAINS } from "./mutations.type";

export interface AutoReplyEmailDomain {
  id: string;
  name: string;
}

export interface EmailDomain {
  id: string;
  name: string;
  error: any;
  errorType: string;
}

export interface DomainsState {
  domains: Array<EmailDomain>;
  domain: EmailDomain;
  domainsPagination: any;
}

export enum GetDomainsKind {
  Processing = 1,
  Processed = 2,
  All = 3
}

export interface GetDomainsQuery {
  search?: string;
  kind?: GetDomainsKind;
}

export interface RegisterDomain {
  name: string;
}

const defaultState = () => {
  return {
    domains: [],
    domain: {
      id: "",
      name: "",
      error: "",
      errorType: ""
    },
    domainsPagination: {}
  } as DomainsState;
};

const state = defaultState();

const getters = {};

const validateDomainRegistration = (response: any) => {
  if (typeof (response) === "undefined") {
    toast.error(" ", "File was not uploaded successfully");
  }
  const uploadCount = response.data.result.uploadedDomainCount;
  if (uploadCount > 0) {
    toast.success(" ", `${response.data.result.uploadedDomainCount} domain${uploadCount > 1 ? "s" : ""} uploaded successfully!`);
  }
  const dupeCount = response.data.result.duplicatedDomainCount;
  if (dupeCount > 0) {
    toast.error(" ", `${response.data.result.duplicatedDomainCount} duplicate${dupeCount > 1 ? "s" : ""} could not be uploaded!`);
  }
};
const actions = {
  async [GET_PENDING_DOMAINS]({ commit }) {
    const response = await DomainsService.getPendingDomains();

    if (typeof (response) === "undefined")
      return;

    commit(SET_DOMAINS, response.data);
  },
  async [GET_INCOMPLETE_DOMAINS]({ commit }) {
    const response = await DomainsService.getIncompleteDomains();

    if (typeof (response) === "undefined")
      return;

    commit(SET_DOMAINS, response.data);
  },
  async [REGISTER_DOMAIN](context, params) {
    const response = await DomainsService.registerDomain(params);
    validateDomainRegistration(response);
    return response.data.result;
  },
  async [UPLOAD_DOMAINS](context, params) {
    const response = await DomainsService.uploadDomains(params);
    validateDomainRegistration(response);
    return response.data.result;
  },
  async [GET_DOMAINS]({ commit }, params: PaginatedQuery<GetDomainsQuery>) {
    params = {
      ...params
    };
    params.query ??= {};
    params.query = { ...params.query };
    params.query.kind = GetDomainsKind.Processing;
    const response = await DomainsService.getDomains(params);

    if (typeof (response) === "undefined")
      return;

    commit(SET_DOMAINS, response.data);
  },
  async [GET_DOMAIN_COUNTS]() {
    const response = await DomainsService.getDomainCounts();

    if (typeof (response) === "undefined")
      return;

    return response.data.result;
  },
  async [UPDATE_DOMAIN](context, params) {
    const response = await DomainsService.updateDomain(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Domain Re-Queued!");
    }
  },
  async [DELETE_DOMAIN](context, params) {
    const response = await DomainsService.deleteDomain(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Deleted Domain!");
    }
  },
  [RESET_DOMAIN]({ commit }) {
    commit(SET_DOMAIN, null);
  }
};

const mutations = {
  [SET_DOMAINS](state, response) {
    state.domains = response.result;
    state.domainsPagination = response.paginationInformation;
  },
  [SET_DOMAIN](state, domain) {
    if (domain)
      state.domain = domain;
    else
      state.domain = {
        name: "",
        error: "",
        errorType: ""
      };
  },
  [RESET_DOMAINS](state) {
    Object.assign(state, defaultState());
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
