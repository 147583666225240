
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "PopupModal",

  data: () => ({
    isVisible: false
  }),

  methods: {
    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    }
  }
});
