import { StoreOptions } from "vuex";
import ApiService, { StandardService } from "../common/api.service";
import { buildStandardStoreActions, buildState, defaultStandardState, StandardState, StandardStateManager } from "./module-builder";

export enum EmployeeEventActionErrorKind
{
    Unknown = 1,
    NoTemplate = 2,
    UnableToGenerateEmail = 3,
    UnableToSend = 4,
    NoSupplier = 5
}

export interface ErrorLogGetResult {
  id: string;
  to?: string;
  from?: string;
  subject?: string;
  date?: string;
  reason?: EmployeeEventActionErrorKind;
}

const apiRoot = "ErrorLog";

export type ErrorLogState = StandardState<ErrorLogGetResult>;

export const errorLogActions = buildStandardStoreActions(apiRoot);

export class ErrorLogService extends StandardService<any>{
  constructor() {
    super(apiRoot);
  }

  public async retry(id: string) {
    ApiService.setHeader();

    return ApiService.post(`${this.rootApi}/retry?id=${id}`);
  }

}

export default {
  state: defaultStandardState<ErrorLogState, ErrorLogGetResult>(),
  ...buildState(
    errorLogActions,
    new StandardStateManager<StandardService, ErrorLogState, ErrorLogGetResult, any>(
      new ErrorLogService(),
      errorLogActions,
      params => {
        return {
          name: params.id ?? "",
        };
      }
    )
  )
} as StoreOptions<any>;
