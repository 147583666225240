const ID_TOKEN_KEY = "id_token";

export const getToken = (): string => {
  return window.localStorage.getItem(ID_TOKEN_KEY) as string;
};

export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
