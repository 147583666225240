
import { PaginationInformation } from "@/common/contracts/paginated.result";
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";

const VPagination = defineComponent({
  name: "VPagination",
  props: {
    pagination: {
      type: Object as PropType<PaginationInformation>,
      required: true
    }
  },
  computed: {
    pages() {
      if (
        !this.pagination ||
        (this.pagination.totalResults ?? 0) <= this.pagination.itemsPerPage
      ) {
        return [1];
      }

      const totalPages = Math.ceil(
        (this.pagination.totalResults ?? 0) / this.pagination.itemsPerPage
      );
      const currentPages = Array.from(
        new Array(5),
        (x, i) => i + (this.pagination.currentPage - 2)
      );
      const maxPages = Array.from(new Array(1), (x, i) => i + totalPages);
      const minPages = Array.from(new Array(1), (x, i) => i + 1);

      const allPages = [
        ...new Set([...minPages, ...currentPages, ...maxPages])
      ].filter((x) => x > 0 && x <= totalPages);
      const returnPages = new Array<any>();
      for (let i = 0; i < allPages.length; i++) {
        returnPages.push(allPages[i]);
        if (allPages[i + 1] - allPages[i] > 1) {
          returnPages.push("...");
        }
      }
      return returnPages;
    }
  },
  methods: {
    changePage(goToPage) {
      if (goToPage === this.pagination?.currentPage) return;
      this.$emit("update:currentPage", goToPage);
      this.$emit("page-changed", goToPage);
    },
    paginationClass(page) {
      let currentClass =
        this.pagination?.currentPage === page ? "btn-primary text-white" : "";
      currentClass += page === "..." ? "ellipse" : "";
      return currentClass;
    }
  }
});
export default VPagination;
