import { IntelliSpace } from "./intellispace";

export declare type EnumType =
  | { [key: string]: string }
  | { [key: number]: string };

export declare type EnumKeyType = number | string;

export interface EnumDefinition<T = EnumKeyType> {
  entries: EnumEntry<T>[];
  isNumeric: boolean;
}
export interface EnumEntry<T = EnumKeyType> {
  name: string;
  friendlyName: string;
  value: T;
  isNumeric: boolean;
}

export class Enum {
  private static AllValuesMap = new Map<any, any[]>();

  public static AllValues<T>(enumType: any): T[] {
    if (!this.AllValuesMap.has(enumType)) {
      const result = new Array<T>();
      for (const color in enumType) {
        result.push(enumType[color]);
      }
      this.AllValuesMap.set(enumType, result);
    }
    return this.AllValuesMap.get(enumType) ?? [];
  }

  public static HasFlag<T extends number>(value: T, has: T) {
    return (value === 0 && has === 0) || (value & has) !== 0;
  }

  public static GetFriendlyName(type: EnumType, value: any) {
    return type[value] == null ? "" : IntelliSpace.Format(type[value]);
  }

  public static Entries(type: EnumType): EnumDefinition<EnumKeyType> {
    const entries = new Array<EnumEntry>();
    let indexers = new Array<{
      key: string;
      value: EnumKeyType;
    }>();
    for (const enumOption in type) {
      indexers.push({
        key: enumOption,
        value: (type as any)[enumOption],
      });
    }
    const withNumber = indexers.filter(_ => typeof _.value === "number");
    const isNumeric = withNumber.length > 0;
    if (isNumeric) {
      indexers = withNumber;
    }
    for (const indexer of indexers) {
      entries.push({
        name: indexer.key,
        friendlyName: isNumeric
          ? IntelliSpace.Format(indexer.key)
          : IntelliSpace.Format(indexer.value as string),
        value: indexer.value,
        isNumeric: isNumeric,
      });
    }
    return {
      entries,
      isNumeric,
    };
  }
}
