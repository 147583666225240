import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-xl mb-0"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isNotEmpty(_ctx.title))
      ? (_openBlock(), _createElementBlock("h5", _hoisted_1, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.isNotEmpty(_ctx.message))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true)
  ], 64))
}