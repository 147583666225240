import { UsersService } from "@/common/api.service";

import {
  SET_USERS,
  SET_USER,
  RESET_USERS_STATE
} from "./mutations.type";

import {
  GET_USERS,
  GET_USER,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  SEND_RESET_PASSWORD,
  RESET_USER
} from "./actions.type";
import { toast } from "../common/toast/toast.service";

export interface IUserAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  town: string;
  county: string;
  postcode: string;
}

export interface IUserContact {
  title: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
}

export interface IUser {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  occupancyType: string;
  pricePoint: string;
  referrerName: string;
  isReferrerPaid: boolean;
  performanceRating: string;
  companies: Array<any>;
  address: IUserAddress;
  contact: IUserContact;
}

export interface UsersState {
  usersPagination: any;
  users: Array<IUser>;
  user: IUser;
}

const defaultState = () => {
  return {
    usersPagination: {},
    users: [],
    user: {
      id: "",
      name: "",
      email: "",
      phoneNumber: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        town: "",
        county: "",
        postcode: ""
      },
      contact: {
        title: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailAddress: ""
      },
      occupancyType: "0",
      pricePoint: "",
      referrerName: "",
      isReferrerPaid: false,
      performanceRating: "0",
      companies: []
    }
  } as UsersState;
};

const state = defaultState();

const getters = {};

const actions = {
  async [GET_USERS]({ commit }, params) {
    const response = await UsersService.getUsers(params);

    if (typeof (response) === "undefined")
      return;

    commit(SET_USERS, response.data);
  },
  async [GET_USER]({ commit }, params) {
    const { data } = await UsersService.getUser(params);

    commit(SET_USER, data);
  },
  async [ADD_USER]({ commit }, params) {
    const response = await UsersService.createUser(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success("The user will be sent an email with a link to the app and to set their password", "User added!");

      commit(SET_USER, response.data.result);
    }
  },
  async [UPDATE_USER](context, params) {
    const response = await UsersService.updateUser(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "User updated!");
    }
  },
  async [DELETE_USER](context, params) {
    const response = await UsersService.deleteUser(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "User locked out!");
    }
  },
  async [SEND_RESET_PASSWORD](context, params) {
    const response = await UsersService.sendResetPassword(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success("The user will receive an email with instructions on how to reset their password", "Sent reset password email!");
    }
  },
  [RESET_USER]({ commit }) {
    commit(SET_USER, null);
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
const mutations = {
  [SET_USERS](state, data) {
    state.users = data.result;
    state.usersPagination = data.paginationInformation;
  },
  [SET_USER](state, data) {
    if (data) {
      state.user = data.result;
    }
    else
      state.user = defaultState().user;
  },
  [RESET_USERS_STATE](state) {
    Object.assign(state, defaultState());
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
