/* 
Because we use our own build of CKEditor with the placeholder (https://www.npmjs.com/package/ckeditor5-placeholder) 
plugin, we can only import the type, not the module (hence `import type`) otherwise we'll get duplicate-moduel-error
as it imports both our build and the node_modules build.

The CkEditor class below facilitates typed access to our custom build of CKEditor.
*/

import type ClassicEditorType from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import type { EditorConfig } from "@ckeditor/ckeditor5-core/src/editor/editorconfig";

declare const ClassicEditor: typeof ClassicEditorType;
export type TClassicEditor = ClassicEditorType;
export interface CustomEditorConfig extends EditorConfig {
    placeholderBrackets?: {
        open: string,
        close: string
    }
    placeholderProps?: {
        types: Array<string>
    }
}
export class CkEditor {
    public static addToolbarItem(config: CustomEditorConfig, item: string): CustomEditorConfig {
        if (config.toolbar && !Array.isArray(config.toolbar) && config.toolbar.items) {
            config.toolbar.items.push(item);
        }
        return config;
    }

    public static addPlaceholderToolbarItem(config: CustomEditorConfig, ...placeholders: string[]) {
        config = this.addToolbarItem(config, "placeholder");
        config.placeholderProps = { types: placeholders };
        return config;
    }

    public static newCustomConfig(configure?: (config: CustomEditorConfig) => void): CustomEditorConfig {
        const config = JSON.parse(JSON.stringify(this.CustomClassicEditor.defaultConfig)) as CustomEditorConfig;
        config.placeholderBrackets = {
            open: "{{",
            close: "}}"
        }
        if (configure) {
            configure(config);
        }
        return config;
    }

    public static get CustomClassicEditor(): typeof ClassicEditorType {
        return ClassicEditor;
    }
}