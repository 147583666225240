import { AxiosResponse } from "axios";
import { StoreOptions } from "vuex";
import { GetPagedRequest } from "../common/api.models";
import ApiService, { StandardService } from "../common/api.service";
import { PagedOperationResult, PaginatedQuery } from "../common/contracts/paginated.result";
import { toast } from "../common/toast/toast.service";
import { buildStandardStoreActions, buildState, defaultStandardState, StandardState, StandardStateManager } from "./module-builder";

export interface CompanyPutPost {
  name: string;
}

export interface CompanyGetResult {
  id?: string;
  name?: string;
  companyNumber?: string;
  telephoneNumber?: string;
  emailAddress?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  postCode?: string;
  configuration?: { id: string, templates?: SupplierConfigurationTemplate[] },
  welcomeEmailTemplateId?: string;
  welcomeEmailTemplateIdPrevious?: string;
}

export interface SupplierConfigurationTemplate {
  dateCreated: Date;
  template?: EmailTemplate;
  templateId?: string;
  templateKind?: EmailTemplateKind;
}

export enum EmailTemplateKind {
  WelcomeEmail = 1,
}

export interface EmailTemplate {
  id: string;
  name: string;
}

export interface CompanyGetPagedRequest extends PaginatedQuery {
  search?: string;
}

const apiRoot = "Companies";

export type CompaniesState = StandardState<CompanyGetResult>;

export const companiesActions = buildStandardStoreActions(apiRoot);

export class CompaniesService extends StandardService<CompanyPutPost, CompanyGetResult> {
  constructor() {
    super(apiRoot);
  }

  public override async getPaged(params: GetPagedRequest): Promise<AxiosResponse<PagedOperationResult<any[]>, any>> {
    const result = await super.getPaged(params);
    if (result.status === 200) {
      for (let i = 0; i < result.data.result.length; i++) {
        const item = result.data.result[i];
        item.welcomeEmailTemplateId = this.findWelcomeEmailTemplateId(item);
        item.welcomeEmailTemplateIdPrevious = item.welcomeEmailTemplateId;
      }
    }
    return result;
  }

  private findWelcomeEmailTemplateId(company: CompanyGetResult) {
    return (company.configuration?.templates ?? []).find(
      (_) => _.templateKind === EmailTemplateKind.WelcomeEmail
    )?.templateId;
  }

  public async setEmailTemplate(supplierId: string, templateId: string) {
    ApiService.setHeader();
    const result = await ApiService.post(`${this.rootApi}/${supplierId}`, { templateId });
    const success = result.status === 200;
    if (success) {
      toast.success("Changed email template");
    }
    return success;
  }
}

export default {
  state: defaultStandardState<CompaniesState, CompanyGetResult>(),
  ...buildState(
    companiesActions,
    new StandardStateManager<StandardService, CompaniesState, CompanyGetResult, CompanyPutPost>(
      new CompaniesService(),
      companiesActions,
      params => {
        return {
          name: params.name ?? "",
        };
      }
    )
  )
} as StoreOptions<any>;
