
import { mapState } from "vuex";
import { FORGOT_PASSWORD, LOG_ERROR } from "@/store/actions.type";
import { defineComponent, State } from "vue";

export default defineComponent({
  name: "CSForgotPassword",
  data() {
    return {
      emailAddress: null,
      emailSent: false
    };
  },
  computed: {
    ...mapState({
      errors: state => (state as State).auth.errors
    })
  },
  methods: {
    onSubmit() {
      this.$store.dispatch(FORGOT_PASSWORD, { email: this.emailAddress }).then(data => {
        if (data.succeeded === true) {
          this.emailSent = true;
        } else {
          this.$store.dispatch(LOG_ERROR, data.errors);
        }
      });
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch(LOG_ERROR, []);

    next();
  }
});
