
import { defineComponent } from "vue-demi";
import PopupModal from "./PopupModal.vue";
interface ConfirmOptions {
  title?: string;
  message?: string;
  okButton?: string;
  cancelButton?: string;
}
export interface IConfirmDialog {
  show(options?: ConfirmOptions): Promise<boolean>;
}
export default defineComponent({
  name: "ConfirmDialog",

  components: { PopupModal },

  data() {
    return {
      // Parameters that change depending on the type of dialogue
      title: undefined as string | undefined,
      message: undefined as string | undefined, // Main text content
      okButton: undefined as string | undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
      cancelButton: "Cancel", // text for cancel button

      // Private variables
      resolvePromise: (undefined as any) as (val: any) => void,
      rejectPromise: (undefined as any) as (val: any) => void
    };
  },

  methods: {
    show(opts: ConfirmOptions = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      (this.$refs as any).popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      (this.$refs as any).popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      (this.$refs as any).popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    }
  }
});
