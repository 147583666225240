import { toast } from "@/common/toast/toast.service";
import { StoreOptions } from "vuex";
import { ItemRequest } from "../common/api.models";
import { StandardService } from "../common/api.service";
import { PaginationInformation } from "../common/contracts/paginated.result";
import { GetPagedRequest } from "../common/api.models";

export interface StandardState<TGetPaged = any> {
    items: Array<TGetPaged>;
    selected: TGetPaged;
    pagination: PaginationInformation;
}

export const defaultStandardState = <T extends StandardState<TModel>, TModel>() => {
    return {
        items: new Array<TModel>(),
        selected: {
        } as TModel,
        pagination: {} as PaginationInformation
    } as T;
}

export type StoreActions = {
    getPaged: string;
    setPaged: string;
    resetPaged: string;
    get: string;
    set: string;
    reset: string;
    create: string;
    update: string;
    delete: string;
}

export class StandardStateManager<TService extends StandardService,
    TState extends StandardState<TModelReceive>, TModelReceive, TModelSend> {
    constructor(protected service: TService,
        protected actions: StoreActions,
        protected map: (model: TModelReceive) => TModelSend = _ => _ as any as TModelSend) {

    }

    // actions

    public async getPaged(context: any, params: GetPagedRequest) {
        const response = await this.service.getPaged(params);

        if (typeof (response) === "undefined")
            return;


        context.commit(this.actions.setPaged, response.data);
    }

    public async get(context: any, params: ItemRequest) {
        const response = await this.service.get(params);

        if (typeof (response) === "undefined")
            return;

        context.commit(this.actions.set, response.data.result);
        return response;
    }

    public async create(context: any, params: TModelReceive) {
        const response = await this.service.create({
            model: this.map(params)
        });

        if (typeof (response) === "undefined")
            return;

        if (response.data.succeeded === true) {
            toast.success(" ", "Created!");
        }
        return response;
    }
    public async update(context: any, params: TModelReceive) {
        const response = await this.service.update({
            model: this.map(params),
            id: params["id"] ?? ""
        });

        if (typeof (response) === "undefined")
            return;

        if (response.data.succeeded === true) {
            toast.success(" ", "Updated!");
        }
    }
    public async delete(context: any, params: ItemRequest) {
        const response = await this.service.delete(params);

        if (typeof (response) === "undefined")
            return;

        if (response.data.succeeded === true) {
            toast.success(" ", "Deleted!");
        }
    }
    public reset(context: any) {
        context.commit(this.actions.set, null);
    }

    // mutations

    public setPaged(state: TState, response: any) {
        state.items = response.result;
        state.pagination = response.paginationInformation;
    }
    public set(state: TState, model: TModelReceive) {
        state.selected = model ?? {} as TModelReceive;
    }
    public resetPaged(state: TState) {
        Object.assign(state, defaultStandardState());
    }
}

export function buildStandardStoreActions<T extends StoreActions = StoreActions>(prefix: string): T {
    const format = (name: string) => `${prefix}_${name}`;
    return {
        getPaged: format("GetPaged"),
        setPaged: format("SetPaged"),
        get: format("Get"),
        set: format("Set"),
        create: format("Create"),
        delete: format("Delete"),
        update: format("Update"),
        reset: format("Reset"),
        resetPaged: format("ResetPaged")
    } as T;
}

export function buildState<T extends StandardStateManager<any, any, any, any>>(model: StoreActions, service: T): StoreOptions<any> {
    const result = {
        actions: {},
        mutations: {}
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    const apply = (fn: Function) => (...a) => fn.apply(service, [...a] as any);
    // eslint-disable-next-line @typescript-eslint/ban-types
    const add = (name: string, fn: Function, to: any) => {
        if (name) {
            to[name] = apply(fn);
        }
    }
    add(model.getPaged, service.getPaged, result.actions);
    add(model.get, service.get, result.actions);
    add(model.create, service.create, result.actions);
    add(model.update, service.update, result.actions);
    add(model.delete, service.delete, result.actions);
    add(model.reset, service.reset, result.actions);

    add(model.set, service.set, result.mutations);
    add(model.setPaged, service.setPaged, result.mutations);
    add(model.resetPaged, service.resetPaged, result.mutations);
    return result;
}
