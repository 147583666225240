import { createStore } from "vuex";

import global from "./global.module";
import auth from "./auth.module";
import folders from "./folders.module";
import rules from "./rules.module";
import bulkRules from "./bulkRules.module";
import emails from "./emails.module";
import users from "./users.module";
import searchResults from "./searchresults.module";
import domains from "./domains.module";
import autoReplies from "./auto-replies.module";
import emailTemplates from "./email-templates.module";
import companies from "./companies.module";
import domainGreenlist from "./domain-greenlist.module";
import sentEmails from "./sent-emails.module";
import errorLog from "./error-log.module";

export default createStore({
  mutations: {},
  actions: {},
  modules: {
    global,
    auth,
    folders,
    rules,
    bulkRules,
    emails,
    users,
    searchResults,
    domains,
    autoReplies,
    emailTemplates,
    companies,
    sentEmails,
    errorLog,
    domainGreenlist
  },
});
