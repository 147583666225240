import { PaginatedQuery } from "./../common/contracts/paginated.result";
import { StoreOptions } from "vuex";
import ApiService, { StandardService } from "../common/api.service";
import { MessageMethodKind, MessageSourceKind } from "./emails.module";
import { buildStandardStoreActions, buildState, defaultStandardState, StandardState, StandardStateManager } from "./module-builder";
import { GetPagedRequest } from "../common/api.models";

export interface SentEmailGetResult {
  id?: string;
  subject?: string;
  toString?: string;
  workerReference?: string;
  from?: string;
  method?: MessageMethodKind;
  source?: MessageSourceKind;
  dateSent?: string;
  companyName?: string;
  companyNumber?: string;
  domain?: string;
}

const apiRoot = "Sent";

export type SentEmailsState = StandardState<SentEmailGetResult>;

export const sentEmailsActions = buildStandardStoreActions(apiRoot);

export interface SentEmailsPaginatedQuery extends PaginatedQuery {
  search: string;
  senders: string;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  methodKind?: MessageMethodKind;
  sourceKind?: MessageSourceKind;
  company?: string | null;
  domain?: string | null;
}

export class SentEmailsService extends StandardService<any>{
  constructor() {
    super(apiRoot);
  }

  public async getPagedCsv(params?: GetPagedRequest) {
    ApiService.setHeader();

    params ??= {} as GetPagedRequest;
    params.paginationInformation ??= {} as any;
    if (params.paginationInformation) {
      params.paginationInformation.currentPage ??= 1;
      params.paginationInformation.itemsPerPage ??= 10;
    }
    params.sortInformation ??= {} as any;
    if (params.sortInformation) {
      params.sortInformation.descending ??= false;
    }

    ApiService.download(`${this.rootApi}/csv`, { params });
  }

}

export default {
  state: defaultStandardState<SentEmailsState, SentEmailGetResult>(),
  ...buildState(
    sentEmailsActions,
    new StandardStateManager<StandardService, SentEmailsState, SentEmailGetResult, any>(
      new SentEmailsService(),
      sentEmailsActions,
      params => {
        return {
          name: params.subject ?? "",
        };
      }
    )
  )
} as StoreOptions<any>;
