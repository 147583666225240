

import { defineComponent } from "vue";
import { MenuItem } from "@headlessui/vue"

const CustomMenuItem = defineComponent ({
  name: "CustomMenuItem",
  components: {
    MenuItem
  },
  props: {
    buttonName: {
      type: String,
      default: ""
    },
    sortIsDescending: {
      type: Boolean,
      default: null as boolean | null
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: null
    }
  }
});

export default CustomMenuItem;

export interface MenuItemDefinition {
  name: string;
  key?: string | null;
}
