import {GET_RULES, GET_RULE, CREATE_RULE, UPDATE_RULE, DELETE_RULE, RESET_RULE, BLOCK_SENDER} from "./actions.type";
import { SET_RULES, SET_RULE, RESET_RULES } from "./mutations.type";

import { RuleService} from "@/common/api.service";
import { toast } from "../common/toast/toast.service";

export interface RulesState {
  rules: Array<any>;
  rule: {
    id: string;
    name: string;
    field: string;
    pattern: string;
    value: string;
    destinationFolderId: string;
    destinationFolder: any;
  },
  rulesPagination: any;
}

export interface BlockedSenderModel {
  emailAddress: string;
}

const defaultState = () => {
  return {
    rules: [],
    rule: {
      id: "",
      name: "",
      field: "",
      pattern: "",
      value: "",
      destinationFolderId: "",
      destinationFolder: {}
    },
    rulesPagination: {}
  } as RulesState;
};

const state = defaultState();

const getters = {};

const actions = {
  async [GET_RULES]({ commit }, params) {
    const response = await RuleService.getRules(params);

    if (typeof (response) === "undefined")
      return;

    commit(SET_RULES, response.data);
  },
  async [GET_RULE]({ commit }, params) {
    const response = await RuleService.getRule(params);

    if (typeof (response) === "undefined")
      return;

    commit(SET_RULE, response.data.result);
  },
  async [BLOCK_SENDER](context: any, params: BlockedSenderModel | null = null) {
    const response = await RuleService.blockSender(params);

    if (typeof (response) === "undefined") {
      toast.error(" ", `Failed to block email address: ${params?.emailAddress}`);
      return;
    }

    toast.success(" ",`From email address ${params?.emailAddress} is blocked`);

    return response.status == 200;
  },
  async [CREATE_RULE](context, params) {
    const response = await RuleService.createRule(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Created rule!");
    }
  },
  async [UPDATE_RULE](context, params) {
    const response = await RuleService.updateRule(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Updated rule!");
    }
  },
  async [DELETE_RULE](context, params) {
    const response = await RuleService.deleteRule(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Deleted rule!");
    }
  },
  [RESET_RULE]({ commit }) {
    commit(SET_RULE, null);
  }
};

const mutations = {
  [SET_RULES](state, response) {
    state.rules = response.result;
    state.rulesPagination = response.paginationInformation;
  },
  [SET_RULE](state, rule) {
    if (rule)
      state.rule = rule;
    else
      state.rule = {
        id: "",
        name: "",
        field: "",
        pattern: "",
        value: "",
        destinationFolderId: "",
        destinationFolder: {}
      };
  },
  [RESET_RULES](state) {
    Object.assign(state, defaultState());
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
