
import { defineComponent } from "vue";

export default defineComponent({
  name: "ToastComponent",
  props: {
    title: String,
    message: String,
    type: String
  },
  methods: {
    isNotEmpty(value: string) {
      return value?.trim() || "" !== "";
    }
  }
});
