
import ConfirmDialog, {
  IConfirmDialog
} from "@/components/common/ConfirmView.vue";
import { mapState } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserCircle,
  faInbox,
  faReply,
  faTrashAlt,
  faEllipsisH,
  faShare,
  faPrint
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

library.add(
  faUserCircle,
  faInbox,
  faReply,
  faClock,
  faTrashAlt,
  faEllipsisH,
  faShare,
  faPrint,
  faExclamationCircle
);

import {
  MOVE_TO_FOLDER,
  GET_EMAILS,
  RESET_EMAIL,
  MOVE_TO_JUNK,
  DELETE_EMAIL
} from "../store/actions.type";
import { MoveToJunkModel } from "@/store/emails.module";
import { defineComponent, State } from "@vue/runtime-core";
import { ref } from "vue";

const EmailHeader = defineComponent({
  name: "EmailHeader",
  setup() {
    const confirmDialog = ref<IConfirmDialog>(null as any);

    return {
      confirmDialog
    };
  },
  components: { ConfirmDialog },
  computed: {
    ...mapState({
      folders: (state) => (state as State).folders
    })
  },
  data() {
    return {
      targetFolderId: ""
    };
  },
  props: {
    from: {
      type: String
    },
    to: {
      type: String
    },
    dateCreated: {
      type: String
    },
    emailId: {
      type: String
    },
    isReply: {
      type: Boolean,
      default: false
    },
    isProtected: {
      type: Boolean,
      default: false
    },
    folderId: {
      type: String
    },
    currentPage: {
      type: Number
    }
  },
  methods: {
    scrollToReply() {
      this.$emit("scrollToReply");
    },
    scrollToForward() {
      this.$emit("scrollToForward");
    },
    moveToFolder() {
      this.$store
        .dispatch(MOVE_TO_FOLDER, {
          id: this.emailId,
          folderId: this.targetFolderId
        })
        .then(() => {
          this.getEmails();
        });
    },
    searchEmails(value) {
      this.$store.dispatch(GET_EMAILS, {
        search: value,
        paginationInformation: {
          itemsPerPage:
            parseInt(window.sessionStorage.getItem("itemsPerPage") ?? "0") ||
            25,
          currentPage: 1
        },
        folderId: this.folderId
      });
      this.$emit("searchEmail", value);
    },
    async moveToJunk(blockSender: boolean) {
      const ok = await this.confirmDialog.show({
        title: "Send to Junk",
        message: `Are you sure you want to send message to Junk${
          blockSender ? ", and block the sender" : ""
        }?`,
        okButton: "Send to Junk"
      });
      if (ok) {
        this.$store
          .dispatch(MOVE_TO_JUNK, {
            id: this.emailId,
            blockSender: blockSender
          } as MoveToJunkModel)
          .then((success) => {
            if (success === true) {
              this.getEmails();
            }
          });
      }
    },
    async deleteEmail() {
      const ok = await this.confirmDialog.show({
        title: "Delete",
        message: "Are you sure you want to delete this email?",
        okButton: "Delete"
      });
      if (ok) {
        this.$store
          .dispatch(DELETE_EMAIL, {
            id: this.emailId
          })
          .then(() => {
            this.getEmails();
          });
      }
    },
    async getEmails() {
      this.$store.dispatch(RESET_EMAIL);
      this.$store.dispatch(GET_EMAILS, {
        paginationInformation: {
          itemsPerPage:
            parseInt(window.sessionStorage.getItem("itemsPerPage") ?? "0") ||
            25,
          currentPage: this.currentPage
        },
        folderId: this.folderId
      });
    }
  }
});
export default EmailHeader;
