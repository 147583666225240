import { PaginationInformation } from "./../common/contracts/paginated.result";
import { EmailService } from "@/common/api.service";

import { GET_FOLDERS, GET_FOLDER, CREATE_FOLDER, SEARCH_EMAILS, RENAME_FOLDER, DELETE_FOLDER, RESET_FOLDER } from "./actions.type";
import { SET_FOLDERS, SET_FOLDER, RESET_FOLDERS } from "./mutations.type";

import { FolderService } from "@/common/api.service";
import { toast } from "@/common/toast/toast.service";

export interface Folder {
  id: string;
  name: string;
  ordinal: number;
  protected: boolean;
  canDeleteEmailsIfProtected: boolean;
  custom: boolean;
  unreadCount: number;
}
export interface FoldersState {
  folders: Array<Folder>;
  folder: Folder;
  foldersPagination: PaginationInformation;
}
const defaultState = () => {
  return {
    folders: [],
    folder: {
      protected: false,
      id: "",
      name: "",
      ordinal: 0,
      custom: false,
      unreadCount: 0,
      canDeleteEmailsIfProtected: false
    },
    foldersPagination: {} as PaginationInformation
  } as FoldersState;
};

const state = defaultState();

const getters = {};

const actions = {
  async [GET_FOLDERS](context: any, params: any = null) {
    const response = await FolderService.getFolders(params);

    if (typeof (response) === "undefined")
      return;

    context.commit(SET_FOLDERS, response.data);
  },
  async [GET_FOLDER](context: any, params: any = null) {
    const response = await FolderService.getFolder(params);

    if (typeof (response) === "undefined")
      return;

    context.commit(SET_FOLDER, response.data.result);
  },
  async [CREATE_FOLDER](context: any, params: any = null) {
    const response = await FolderService.createFolder(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Created folder!");
    }
  },
  async [SEARCH_EMAILS](context: any, params: any = null) {
    const response = await EmailService.searchEmails(params);

    if (typeof (response) === "undefined")
      return;
  },
  async [RENAME_FOLDER](context: any, params: any = null) {
    const response = await FolderService.renameFolder(params);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Updated folder!");
    }
  },
  async [DELETE_FOLDER](context: any, params: any = null) {
    const response = await FolderService.deleteFolder(params.id);

    if (typeof (response) === "undefined")
      return;

    if (response.data.succeeded === true) {
      toast.success(" ", "Deleted folder!");
    }
  },
  [RESET_FOLDER](context: any) {
    context.commit(SET_FOLDER, null);
  }
};

const mutations = {
  [SET_FOLDERS](state: any, response: any) {
    state.folders = response.result;
    state.foldersPagination = response.paginationInformation;
  },
  [SET_FOLDER](state: any, folder: any) {
    if (folder)
      state.folder = folder;
    else
      state.folder = {
        id: "",
        name: "",
        ordinal: 0
      };
  },
  [RESET_FOLDERS](state: any) {
    Object.assign(state, defaultState());
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
