
import { mapState } from "vuex";
import EmailHeader from "./EmailHeader.vue";
import EmailBody from "./EmailBody.vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPaperclip, faCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faPaperclip, faCircle);

import {
  GET_EMAIL,
  CHANGE_READ_STATUS,
  MOVE_TO_FOLDER,
  SEND_REPLY,
  SEND_FORWARD,
  RESET_EMAIL
} from "../store/actions.type";
import { State } from "@vue/runtime-core";
import { defineComponent } from "vue";
import { CkEditor } from "@/common/contracts/ckeditor";

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

const EmailComponent = defineComponent({
  name: "EmailComponent",
  components: {
    EmailHeader,
    EmailBody
  },
  props: {
    emailId: {
      type: String
    },
    currentPage: {
      type: Number
    }
  },
  data() {
    return {
      width: null as any,
      height: null as any,
      observer: null as any as ResizeObserver,
      editor: CkEditor.CustomClassicEditor,
      editorConfig: {
        startupFocus: true
      },

      uploadedFiles: [],
      uploadError: null,
      currentStatus: null as number | null,
      uploadFieldName: "attachments",
      dropdownOpen: false,
      setAllSelected: false,

      pendingReply: {
        emailId: "" as string | null | undefined,
        subject: "" as string | null | undefined,
        body: "",
        attachments: [],
        isInternal: false
      },

      pendingEmail: {
        emailId: "" as string | null | undefined,
        to: "",
        from: "",
        subject: "",
        body: "",
        attachments: [],
        isInternal: false
      },

      loading: true,
      error: "",

      replying: false,
      forwarding: false
    };
  },
  async mounted() {
    this.error = "";
    this.loading = true;
    this.reset();
    const box = this.$refs.box;
    const boxSize = (box as any).getBoundingClientRect();

    this.width = Math.trunc(boxSize.width) + "px";
    this.height = Math.trunc(boxSize.height) + "px";
    // initialize the observer on mount
    this.initObserver();
  },
  computed: {
    ...mapState({
      folders: (state: any) => (state as State).folders.folders,
      folder: (state: any) => (state as State).folders.folder,
      email: (state: any) => (state as State).emails.email
    }),
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  watch: {
    "$route.params.emailId": {
      handler(newVal, oldVal) {
        if (!newVal) {
          this.$store.dispatch(RESET_EMAIL);
        } else if (oldVal !== newVal) {
          this.getEmail(newVal);
          this.reset();
        }
      },
      immediate: true
    }
  },

  methods: {
    initObserver() {
      const box = this.$refs.box as any;
      var observer = new window.ResizeObserver(
        (entries) => (this.$data.width = entries[0].contentRect.width)
      );
      observer.observe(box);
      this.$data.observer = observer;
    },
    getEmail(id, toggle = false) {
      if (toggle === true && (id === "" || this.email.id === id)) {
        this.$store.dispatch(RESET_EMAIL);
      } else {
        this.$store.dispatch(GET_EMAIL, { id: id });
      }
      this.giveUpdatedEmailCount();
    },
    moveToFolder() {
      this.$store.dispatch(MOVE_TO_FOLDER, {
        id: this.email.id,
        folderId: this.email.folderId
      });
    },
    changeReadStatus(isRead) {
      this.$store.dispatch(CHANGE_READ_STATUS, {
        id: this.email.id,
        isRead: isRead
      });
    },
    async forwardEmail() {
      this.pendingEmail.emailId = this.email.id;
      const result = await this.$store.dispatch(
        SEND_FORWARD,
        this.pendingEmail
      );

      if (result) {
        this.reset();
      }
    },
    giveUpdatedEmailCount() {
      this.$emit("updatedEmailCount", 10);
    },
    async sendReply() {
      this.pendingReply.emailId = this.email.id;
      this.pendingReply.subject = this.email.subject;

      const result = await this.$store.dispatch(SEND_REPLY, this.pendingReply);

      if (result === true) {
        this.reset();
      }
    },
    reset() {
      this.$store.dispatch(RESET_EMAIL).then(() => {
        if (this.$route.params.emailId) {
          this.$store.dispatch(GET_EMAIL, { id: this.$route.params.emailId });
        }
      });
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
      this.pendingEmail = {
        emailId: "",
        to: "",
        from: "",
        subject: "",
        body: "",
        attachments: [],
        isInternal: false
      };
      this.pendingReply = {
        emailId: "",
        subject: "",
        body: "",
        attachments: [],
        isInternal: false
      };
      this.replying = false;
      this.forwarding = false;
    },
    filesChange(fieldName, fileList) {
      if (!fileList.length) return;

      this.pendingReply.attachments = fileList;
    },
    async startReply() {
      this.replying = true;
      // var elmnt = document.getElementById("replyArea");
      // elmnt?.scrollIntoView();
    },
    async startForward() {
      this.forwarding = true;
      this.pendingEmail.subject = "FW: " + this.email.subject;
      this.pendingEmail.from = this.email.to ?? "";
      var elmnt = document.getElementById("forwardArea");
      elmnt?.scrollIntoView();
    },
    searchEmail(value) {
      this.$emit("searchEmail", value);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!to.params.emailId) this.$store.dispatch(RESET_EMAIL);

    next();
  }
});
export default EmailComponent;
