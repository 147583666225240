import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import CsInbox from "@/views/Inbox.vue";
import CSLogin from "@/views/Login.vue";
import CSLogout from "@/views/Logout.vue";
import CSForgotPassword from "@/views/ForgotPassword.vue";
import { CHECK_AUTH } from "@/store/actions.type";

const routes: Array<RouteRecordRaw> = [
  {
    name: "home",
    path: "/",
    redirect: "/inbox/c57e0125-aa61-4c63-a26f-1147aac07700",
    props: true
  },
  {
    name: "inbox",
    path: "/inbox/:folderId?/:emailId?",
    component: CsInbox,
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "new-email",
    path: "/new-email",
    component: () => import("@/views/NewEmail.vue"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "print-email",
    path: "/print-email/:emailId",
    component: () => import("@/views/PrintScreen.vue"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "settings",
    path: "/settings/:area?",
    component: () => import("@/views/Settings.vue"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "sent",
    path: "/sent/:emailId?",
    component: () => import("@/views/Sent.vue"),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    name: "login",
    path: "/login",
    component: CSLogin,
    meta: {
      bodyClass: "with-bg"
    }
  },
  {
    name: "logout",
    path: "/logout",
    component: CSLogout,
    meta: {
      bodyClass: "with-bg"
    }
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: CSForgotPassword,
    meta: {
      bodyClass: "with-bg"
    }
  },
  {
    name: "reset-password",
    path: "/reset-password/:token",
    component: () => import("@/views/ResetPassword.vue"),
    meta: {
      bodyClass: "with-bg"
    }
  },
  {
    name: "search-results",
    path: "/search-results/:searchText",
    component: () => import("@/views/SearchResults.vue"),
    meta: {
      requiresAuth: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    await Promise.all([store.dispatch(CHECK_AUTH)]).then(() => {
      if (store.getters["isAuthenticated"] === true) {
        next();
      } else {
        next("login");
      }
    });
  } else {
    next();
  }
});

export default router;
